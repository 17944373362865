import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './VoyageDetailsItem.scss';
import SelectField from '../../../basic/SelectField';
import PortOptions from '../PortOptions';
import { Box, HStack, IconButton, Text, VStack, Flex, Input, Grid } from '@chakra-ui/react';
import CustomFormControl from '../../../core/CustomFormControl';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa';
import DateTimeWithTrappedFocus from '../../../core/DateTimeWithTrappedFocus';
import { checkIfETDEditable } from '../../../../utils';
import { Tooltip } from '@/components/ui/tooltip';
import { IoInformationCircleOutline } from 'react-icons/io5';
import { Alert } from '@/components/ui/alert';

const propTypes = {
  editMode: PropTypes.bool,
  voyage: PropTypes.shape({
    loadPortId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    dischargePortId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    transhipPortId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    shippingLineId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    vesselName: PropTypes.string,
    voyageNumber: PropTypes.string,
    eta: PropTypes.string,
    etd: PropTypes.string,
    ata: PropTypes.string,
    atd: PropTypes.string,
    voyageLegId: PropTypes.number,
  }),
  ports: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  shippingLineOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  validationErrors: PropTypes.shape({
    dateError: PropTypes.bool,
    atDateError: PropTypes.bool,
  }),
  onVoyageValueChange: PropTypes.func,
  onExpansionChange: PropTypes.func,
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
    })
  ),
};

const VoyageDetailsItem = ({
  voyage,
  onVoyageValueChange,
  shippingLineOptions,
  editMode,
  ports,
  validationErrors,
  onExpansionChange,
  documents,
}) => {
  const [state, setState] = useState({
    voyageIsExpanded: true,
    portOfLoadingIsExpanded: true,
    portOfDischargeIsExpanded: true,
  });

  const isDisabled = !editMode;

  const etaProps = {
    className: 'form-control m-input',
    disabled: isDisabled,
  };
  const ataProps = {
    className: 'form-control m-input',
    disabled: isDisabled,
  };
  const ETDEditable = checkIfETDEditable(documents);
  const etdProps = {
    className: 'form-control m-input',
    disabled: isDisabled || !ETDEditable,
  };
  const atdProps = {
    className: 'form-control m-input',
    disabled: isDisabled,
  };

  const { dateError } = validationErrors;
  const { atDateError } = validationErrors;
  // TODO, removed default '' from input fields after fixing backend
  // Backend cannot accept empty string currently as marshamallow fails to deserialise

  useEffect(() => {
    (() => {
      onExpansionChange(state.voyageIsExpanded && state.portOfLoadingIsExpanded && state.portOfDischargeIsExpanded);
    })();
  }, [state]);

  return (
    <VStack gap="21px" align="stretch">
      <VStack gap="11px" width="100%" alignItems="flex-start">
        <HStack justifyContent="space-between" color="accent.one.default" width="150px">
          <Text as="p" fontWeight="medium" color="inherit">
            Voyages
          </Text>
          <IconButton
            variant="unstyled"
            minWidth="18px"
            height="fit-content"
            padding="0px"
            fontSize="12px"
            onClick={() => setState({ ...state, voyageIsExpanded: !state.voyageIsExpanded })}
          >
            {state.voyageIsExpanded ? <FaChevronDown /> : <FaChevronRight />}
          </IconButton>
        </HStack>
        {state.voyageIsExpanded && (
          <Grid gap="20px" templateColumns="repeat(4, 1fr)" width="100%">
            <CustomFormControl
              width="100%"
              label={
                <>
                  Vessel Name&nbsp;
                  <Tooltip
                    content="Provides the name of the vessel transporting the goods."
                    positioning={{ placement: 'right-end' }}
                  >
                    <IconButton
                      width="14px"
                      height="14px"
                      padding="0"
                      minW="auto"
                      borderRadius="50%"
                      color="#878787"
                      variant="unstyled"
                    >
                      <IoInformationCircleOutline size="14px" />
                    </IconButton>
                  </Tooltip>
                </>
              }
            >
              <Input
                backgroundColor="white"
                name="vesselName"
                value={voyage?.vesselName || ''}
                disabled={isDisabled}
                className="form-control"
                onChange={e => onVoyageValueChange(voyage, (e && e.target.value) || null, 'vesselName')}
              />
            </CustomFormControl>
            <CustomFormControl
              width="100%"
              label={
                <>
                  Voyage Number&nbsp;
                  <Tooltip
                    content="Provides the voyage number assigned to the shipment."
                    positioning={{ placement: 'right-end' }}
                  >
                    <IconButton
                      width="14px"
                      height="14px"
                      padding="0"
                      minW="auto"
                      borderRadius="50%"
                      color="#878787"
                      variant="unstyled"
                    >
                      <IoInformationCircleOutline size="14px" />
                    </IconButton>
                  </Tooltip>
                </>
              }
            >
              <Input
                backgroundColor="white"
                name="voyageNumber"
                value={voyage?.voyageNumber || ''}
                disabled={isDisabled}
                className="form-control"
                onChange={e => onVoyageValueChange(voyage, (e && e.target.value) || null, 'voyageNumber')}
              />
            </CustomFormControl>

            <SelectField
              width="100%"
              label={
                <>
                  Shipping Line&nbsp;
                  <Tooltip
                    content="Select the shipping line from the dropdown list."
                    positioning={{ placement: 'right-end' }}
                  >
                    <IconButton
                      width="14px"
                      height="14px"
                      padding="0"
                      minW="auto"
                      borderRadius="50%"
                      color="#878787"
                      variant="unstyled"
                    >
                      <IoInformationCircleOutline size="14px" />
                    </IconButton>
                  </Tooltip>
                </>
              }
              isClearable
              disabled={isDisabled}
              name="shippingLine"
              defaultValue={voyage.shippingLineId}
              options={shippingLineOptions}
              onChange={e => onVoyageValueChange(voyage, (e && e.value) || null, 'shippingLineId')}
            />
          </Grid>
        )}
      </VStack>
      <VStack gap="11px" width="100%" alignItems="flex-start">
        <HStack justifyContent="space-between" color="accent.one.default" width="150px">
          <Text as="p" fontWeight="medium" color="inherit">
            Port of Loading
          </Text>
          <IconButton
            variant="unstyled"
            minWidth="18px"
            height="fit-content"
            padding="0px"
            fontSize="12px"
            onClick={() => setState({ ...state, portOfLoadingIsExpanded: !state.portOfLoadingIsExpanded })}
          >
            {state.portOfLoadingIsExpanded ? <FaChevronDown /> : <FaChevronRight />}
          </IconButton>
        </HStack>
        {state.portOfLoadingIsExpanded && (
          <Grid gap="20px" templateColumns="repeat(4, 1fr)" width="100%">
            <CustomFormControl
              width="100%"
              label={
                <>
                  Port&nbsp;
                  <Tooltip
                    content="Identifies the port where the goods will be loaded."
                    positioning={{ placement: 'right-end' }}
                  >
                    <IconButton
                      width="14px"
                      height="14px"
                      padding="0"
                      minW="auto"
                      borderRadius="50%"
                      color="#878787"
                      variant="unstyled"
                    >
                      <IoInformationCircleOutline size="14px" />
                    </IconButton>
                  </Tooltip>
                </>
              }
            >
              <PortOptions
                disabled={isDisabled}
                fieldType="LOAD"
                voyage={voyage}
                ports={ports}
                onVoyageValueChange={onVoyageValueChange}
              />
            </CustomFormControl>
            <CustomFormControl
              width="100%"
              label={
                <>
                  ETD&nbsp;
                  <Tooltip
                    content="Provides the Estimated Time of Departure (ETD) for the port of loading."
                    positioning={{ placement: 'right-end' }}
                  >
                    <IconButton
                      width="14px"
                      height="14px"
                      padding="0"
                      minW="auto"
                      borderRadius="50%"
                      color="#878787"
                      variant="unstyled"
                    >
                      <IoInformationCircleOutline size="14px" />
                    </IconButton>
                  </Tooltip>
                </>
              }
            >
              <DateTimeWithTrappedFocus
                id="etd"
                inputProps={etdProps}
                closeOnSelect
                value={voyage.etd}
                onChange={e => onVoyageValueChange(voyage, e._isAMomentObject ? e.format('YYYY-MM-DD') : e, 'etd')} // eslint-disable-line no-underscore-dangle
                timeFormat={false}
                dateFormat="YYYY-MM-DD"
              />
            </CustomFormControl>
            <CustomFormControl
              width="305px"
              label={
                <>
                  ATD&nbsp;
                  <Tooltip
                    content="Provides the Actual Time of Departure (ATD) for the port of loading."
                    positioning={{ placement: 'right-end' }}
                  >
                    <IconButton
                      width="14px"
                      height="14px"
                      padding="0"
                      minW="auto"
                      borderRadius="50%"
                      color="#878787"
                      variant="unstyled"
                    >
                      <IoInformationCircleOutline size="14px" />
                    </IconButton>
                  </Tooltip>
                </>
              }
            >
              <DateTimeWithTrappedFocus
                id="atd"
                inputProps={atdProps}
                closeOnSelect
                value={voyage.atd}
                onChange={e => onVoyageValueChange(voyage, e._isAMomentObject ? e.format('YYYY-MM-DD') : e, 'atd')} // eslint-disable-line no-underscore-dangle
                timeFormat={false}
                dateFormat="YYYY-MM-DD"
              />
            </CustomFormControl>
          </Grid>
        )}
      </VStack>
      <VStack gap="11px" width="100%" alignItems="flex-start">
        <HStack justifyContent="space-between" color="accent.one.default" width="150px">
          <Text as="p" fontWeight="medium" color="inherit">
            Port of Discharge
          </Text>
          <IconButton
            variant="unstyled"
            minWidth="18px"
            height="fit-content"
            padding="0px"
            fontSize="12px"
            onClick={() => setState({ ...state, portOfDischargeIsExpanded: !state.portOfDischargeIsExpanded })}
          >
            {state.portOfDischargeIsExpanded ? <FaChevronDown /> : <FaChevronRight />}
          </IconButton>
        </HStack>
        {state.portOfDischargeIsExpanded && (
          <>
            <Grid gap="20px" templateColumns="repeat(4, 1fr)" width="100%">
              <CustomFormControl
                width="100%"
                label={
                  <>
                    Port&nbsp;
                    <Tooltip
                      content="Specifies the destination port where the goods will be unloaded."
                      positioning={{ placement: 'right-end' }}
                    >
                      <IconButton
                        width="14px"
                        height="14px"
                        padding="0"
                        minW="auto"
                        borderRadius="50%"
                        color="#878787"
                        variant="unstyled"
                      >
                        <IoInformationCircleOutline size="14px" />
                      </IconButton>
                    </Tooltip>
                  </>
                }
              >
                <PortOptions
                  disabled={isDisabled}
                  fieldType="DISCHARGE"
                  voyage={voyage}
                  ports={ports}
                  onVoyageValueChange={onVoyageValueChange}
                />
              </CustomFormControl>

              <CustomFormControl
                width="100%"
                label={
                  <>
                    ETA&nbsp;
                    <Tooltip
                      content="Provides the Estimated Time of Arrival (ETA) at the destination port."
                      positioning={{ placement: 'right-end' }}
                    >
                      <IconButton
                        width="14px"
                        height="14px"
                        padding="0"
                        minW="auto"
                        borderRadius="50%"
                        color="#878787"
                        variant="unstyled"
                      >
                        <IoInformationCircleOutline size="14px" />
                      </IconButton>
                    </Tooltip>
                  </>
                }
              >
                <DateTimeWithTrappedFocus
                  id="eta"
                  inputProps={etaProps}
                  closeOnSelect
                  value={voyage.eta}
                  onChange={e => onVoyageValueChange(voyage, e._isAMomentObject ? e.format('YYYY-MM-DD') : e, 'eta')} // eslint-disable-line no-underscore-dangle
                  timeFormat={false}
                  dateFormat="YYYY-MM-DD"
                />
              </CustomFormControl>

              <CustomFormControl
                width="100%"
                label={
                  <>
                    ATA&nbsp;
                    <Tooltip
                      content="Provides the Actual Time of Arrival (ATA) at the destination port."
                      positioning={{ placement: 'right-end' }}
                    >
                      <IconButton
                        width="14px"
                        height="14px"
                        padding="0"
                        minW="auto"
                        borderRadius="50%"
                        color="#878787"
                        variant="unstyled"
                      >
                        <IoInformationCircleOutline size="14px" />
                      </IconButton>
                    </Tooltip>
                  </>
                }
              >
                <DateTimeWithTrappedFocus
                  id="ata"
                  inputProps={ataProps}
                  closeOnSelect
                  value={voyage.ata}
                  onChange={e => onVoyageValueChange(voyage, e._isAMomentObject ? e.format('YYYY-MM-DD') : e, 'ata')} // eslint-disable-line no-underscore-dangle
                  timeFormat={false}
                  dateFormat="YYYY-MM-DD"
                />
              </CustomFormControl>
            </Grid>

            {(dateError || atDateError) && (
              <Box width="305px">
                <Alert
                  message={
                    dateError
                      ? 'Arrival(ETA) cannot occur before departure(ETD)'
                      : 'Arrival(ATA) cannot occur before departure(ATD)'
                  }
                  type="error"
                />
              </Box>
            )}
          </>
        )}
      </VStack>
      {state.portOfDischargeIsExpanded && (
        <Box>
          <Flex gap="20px" width="100%">
            <CustomFormControl
              width="100%"
              label={
                <>
                  Tranship Port&nbsp;
                  <Tooltip
                    content="Select the transhipment port from the dropdown list."
                    positioning={{ placement: 'right-end' }}
                  >
                    <IconButton
                      width="14px"
                      height="14px"
                      padding="0"
                      minW="auto"
                      borderRadius="50%"
                      color="#878787"
                      variant="unstyled"
                    >
                      <IoInformationCircleOutline size="14px" />
                    </IconButton>
                  </Tooltip>
                </>
              }
            >
              &nbsp;
            </CustomFormControl>
          </Flex>
          <Grid gap="20px" templateColumns="repeat(4, 1fr)" width="100%">
            <PortOptions
              disabled={isDisabled}
              fieldType="TRANSHIP"
              voyage={voyage}
              ports={ports}
              onVoyageValueChange={onVoyageValueChange}
            />
          </Grid>
        </Box>
      )}
    </VStack>
  );
};

VoyageDetailsItem.propTypes = propTypes;

export default VoyageDetailsItem;
