import React from 'react';
import PropTypes from 'prop-types';
import { Center, Flex, Input, HStack, IconButton, VStack, Textarea } from '@chakra-ui/react';
import get from 'lodash/get';
import SelectField from '../../basic/SelectField';
import CustomFormControl from '../../core/CustomFormControl';
import { ReactComponent as TrashOutlineIconSVG } from '../../../assets/trash-outline-icon.svg';
import { IoMdAdd } from 'react-icons/io';

const propTypes = {
  surchargeTypeOptions: PropTypes.arrayOf(PropTypes.object),
  unitsOfMeasure: PropTypes.arrayOf(PropTypes.object),
  onSurchargeChange: PropTypes.func,
  onAddSurcharge: PropTypes.func,
  onRemoveSurcharge: PropTypes.func,
  isDisabled: PropTypes.bool,
  form: PropTypes.instanceOf(Object),
  sellCurrency: PropTypes.string,
};

const Surcharges = ({
  onAddSurcharge,
  onRemoveSurcharge,
  onSurchargeChange,
  form,
  unitsOfMeasure,
  isDisabled,
  surchargeTypeOptions,
  sellCurrency,
}) => {
  const handleRemoveSurcharge = index => {
    onRemoveSurcharge('form', 'surcharges', index);
  };

  const uomOptions = unitsOfMeasure.map(el => {
    return { value: el.id, label: el.name };
  });

  return (
    <VStack spacing="24px" align="stretch">
      {form.surcharges.map((item, index) => (
        <HStack spacing="18px" key={index}>
          {/* <!-- Surcharge Type --> */}
          <CustomFormControl width="224px" label="Fee">
            <SelectField
              disabled={isDisabled}
              name="surchargeType"
              value={surchargeTypeOptions.find(surcharge => surcharge.label === item.name) || null}
              options={surchargeTypeOptions}
              onChange={e => onSurchargeChange('form', 'surcharges', 'name', index, e ? e.label : null, true)}
            />
          </CustomFormControl>
          {/* <!-- Quantity --> */}
          <CustomFormControl width="224px" label="Quantity">
            <Input
              backgroundColor="white"
              name="surcharge-quantity"
              type="number"
              min="0"
              value={item.quantity}
              disabled={isDisabled}
              className="form-control"
              onChange={e => {
                const value = get(e, 'target.value');
                onSurchargeChange('form', 'surcharges', 'quantity', index, value);
              }}
              onWheelCapture={e => {
                e.target.blur();
              }}
            />
          </CustomFormControl>
          {/* <!-- Surcharge Type --> */}
          <CustomFormControl width="224px" label="UOM">
            <SelectField
              disabled={isDisabled}
              name="surcharge_uom"
              options={uomOptions}
              value={uomOptions.find(uom => uom.value === item.uomId) || null}
              onChange={e => onSurchargeChange('form', 'surcharges', 'uomId', index, e ? e.value : null)}
            />
          </CustomFormControl>
          {/* <!-- Amount --> */}
          <CustomFormControl width="224px" label="Amount">
            <Flex>
              <Input
                backgroundColor="white"
                name="surcharge-amount"
                type="number"
                min="0"
                value={item.value}
                disabled={isDisabled}
                className="form-control"
                onChange={e => {
                  const value = get(e, 'target.value');
                  onSurchargeChange('form', 'surcharges', 'value', index, value);
                }}
                onWheelCapture={e => {
                  e.target.blur();
                }}
              />
              <Center marginLeft="10px">
                <CustomFormControl label={sellCurrency} />
              </Center>
            </Flex>
          </CustomFormControl>

          {item.name === 'Miscellaneous' && (
            <CustomFormControl width="224px" isRequired>
              <label htmlFor="comment">Comment</label>
              <Textarea
                backgroundColor="white"
                name="comment"
                value={item.comment || ''}
                disabled={isDisabled}
                height="10px"
                resize="none"
                style={{
                  minHeight: '40px',
                  border: !item.comment ? '1px solid red' : '1px solid #E2E8F0',
                }}
                onChange={e => onSurchargeChange('form', 'surcharges', 'comment', index, e.target.value)}
              />
            </CustomFormControl>
          )}

          {/* <!-- CONTROLS --> */}
          {!isDisabled && (
            <HStack spacing="5px" marginTop="26px!important" marginLeft="35px !important">
              <IconButton
                colorScheme="actionSecondary"
                onClick={() => onAddSurcharge()}
                disabled={isDisabled}
                height="25px"
                minWidth="25px"
                borderRadius="14px"
              >
                <IoMdAdd />
              </IconButton>
              <IconButton
                disabled={isDisabled}
                variant="ghost"
                onClick={() => handleRemoveSurcharge(index)}
                height="25px"
                width="25px"
                fontSize="25px"
              >
                <TrashOutlineIconSVG />
              </IconButton>
            </HStack>
          )}
        </HStack>
      ))}
    </VStack>
  );
};

Surcharges.propTypes = propTypes;
export default Surcharges;
