import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Heading } from '@chakra-ui/react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import startCase from 'lodash/startCase';

import { fetchNetsuitePurchaseOrder } from '../../../../actions/actions_orders';
import { fetchUsers } from '../../../../actions/actions_users';
import { fetchEntitiesData, selectEntities } from '../../../../slices/masterData/entityManagerSlice';

import { selectNetsuitePurchaseOrder } from '../../../../reducers/reducer_orders';
import { selectCurrentUser } from '../../../../reducers/reducer_user';
import { fmgUsersSelector } from '../../../../reducers/reducer_users';
import { useParams } from 'react-router';
import Loading from '../../../basic/Loading';
import BoabForm from '../../../core/formik/Form';

import { initialize, validationSchema, formConfig } from './formConfig';
import Constants from '../../../../Constants';

// --------------------------------------
// HANDLERS
// --------------------------------------

// TODO -- Formatting of form payload for sending to the BE is still a WIP.
function submitPurchaseOrderCardV2(formValues, props) {
  // const { dispatch, token, copiesCount } = props;
  // // Add additional field(s)
  // const form = {
  //   status: 'ordered',
  //   ...formValues,
  // };
  // Recurring Orders
  // if (formValues.isRecurring) {
  //   const recurringOrder = prepareRecurringOrder(form, props, Constants.PO_FORM_TYPE.AMERICA_DOMESTIC);
  //   dispatch(sendRecurringOrder(recurringOrder, token));
  // } else {
  //   const orderToSend = prepareAmericanOrder(form, props);
  // dispatch(sendOrder(orderToSend, copiesCount, token));
  // }
}

// --------------------------------------
// FORM
// --------------------------------------
function PurchaseOrderCardV2(props) {
  const { poId } = useParams();
  const dispatch = useDispatch();
  const handleSubmit = useCallback(values => {
    return submitPurchaseOrderCardV2(values, { ...props, dispatch });
  }, []);

  const entities = useSelector(selectEntities) || {};
  const users = useSelector(fmgUsersSelector) || {};
  const currentUser = useSelector(selectCurrentUser);
  const netsuitePurchaseOrder = useSelector(selectNetsuitePurchaseOrder);

  useEffect(() => {
    // Fetch entities (uses MasterData helper)
    dispatch(fetchEntitiesData(['input_product', 'grinder', 'packer', 'packer_plant', 'form_fields', 'transporter']));
    // Fetch Users
    dispatch(fetchUsers());

    dispatch(fetchNetsuitePurchaseOrder(poId, props.token));
  }, []);

  const [options, setOptions] = useState({
    grinderOptions: [],
    packerOptions: [],
    packerPlantOptions: [],
    inputProductOptions: [],
    transporterOptions: [],
    dispositionOptions: [],
    dropTrailerOptions: [],
    nofNameOptions: [],
    formulaNameOptions: [],
    contractNameOptions: [],
    tagOptions: [],
    orderTypeOptions: [],
    buyerOptions: [],
    formFieldOptions: [],
  });

  const [initialValues, setInitialValues] = useState();

  const buttons = {};

  const isLoading =
    isEmpty(get(entities, 'grinder.entities') || []) ||
    isEmpty(get(entities, 'packer.entities') || []) ||
    isEmpty(get(entities, 'packer_plant.entities') || []) ||
    isEmpty(get(entities, 'input_product.entities') || []) ||
    isEmpty(get(entities, 'form_fields.entities') || []) ||
    isEmpty(get(entities, 'transporter.entities') || []) ||
    !netsuitePurchaseOrder;

  useEffect(() => {
    if (isLoading) return;
    const { grinder, packer, packer_plant, input_product, form_fields, transporter } = entities;

    const opts = {
      grinderOptions: grinder.entities.map(g => ({ ...g, label: g.name, value: g.uid })),
      packerOptions: packer.entities.map(p => ({ ...p, label: p.name, value: p.id })),
      packerPlantOptions: packer_plant.entities.map(pp => ({ ...pp, label: pp.name, value: pp.id })),
      inputProductOptions: input_product.entities.map(ip => ({ ...ip, label: ip.name, value: ip.uid })),
      buyerOptions: users.map(u => ({
        ...u,
        label: `${u.first_name} ${u.last_name}`,
        value: u.id,
      })),

      transporterOptions: transporter.entities.map(tr => ({ ...tr, label: tr.name, value: tr.id })),
      formFieldOptions: form_fields.entities.map(ff => ({ ...ff, label: startCase(ff.value) })),
      dropTrailerOptions: Constants.DROP_TRAILER_OPTIONS,
    };
    setOptions({
      ...opts,
      // Form Fields (managed in Master Data)
      dispositionOptions: opts.formFieldOptions.filter(itm => itm.type === 'po_disposition'),
      tagOptions: opts.formFieldOptions.filter(itm => itm.type === 'cost_saving_type'),
      orderTypeOptions: opts.formFieldOptions.filter(itm => itm.type === 'price_type'),
      nofNameOptions: opts.formFieldOptions.filter(itm => itm.type === 'nof_name'),
      contractNameOptions: opts.formFieldOptions.filter(itm => itm.type === 'contract_name'),
      formulaNameOptions: opts.formFieldOptions.filter(itm => itm.type === 'formula_name'),
    });

    const packerPlantInOrder = opts.packerPlantOptions.find(itm => itm.value === netsuitePurchaseOrder.packer_plant_id);
    const packerInOrder = opts.packerOptions.find(itm => itm.value === packerPlantInOrder.packer_id);
    const poDispositionInOrder = opts.formFieldOptions
      .filter(itm => itm.type === 'po_disposition')
      .find(itm => itm.value === netsuitePurchaseOrder.po_disposition);
    const transporterInOrder = opts.transporterOptions.find(itm => itm.value === netsuitePurchaseOrder.transporter_id);
    const dropTrailerOpation = opts.dropTrailerOptions.find(
      itm => itm.value === (netsuitePurchaseOrder.drop_trailer || '')
    );

    // Initialize Form
    setInitialValues(
      initialize(
        netsuitePurchaseOrder,
        packerPlantInOrder,
        packerInOrder,
        poDispositionInOrder,
        transporterInOrder,
        dropTrailerOpation,
        opts.inputProductOptions,
        options.orderTypeOptions
      )
    );
  }, [isLoading]);

  const context = {
    options,
    buttons,
  };

  return (
    <>
      <Heading ml="50px" size="md">
        Purchase Order Card (Order ID: {poId})
      </Heading>
      {isLoading || !initialValues ? (
        <Loading />
      ) : (
        <BoabForm
          {...{
            formConfig,
            initialValues,
            validationSchema,
            onSubmit: handleSubmit,
            context,
          }}
        />
      )}
    </>
  );
}

PurchaseOrderCardV2.propTypes = {};

export default PurchaseOrderCardV2;
