import React from 'react';
import PropTypes from 'prop-types';

import SelectField from '../../basic/SelectField';

const propTypes = {
  isDisabled: PropTypes.bool,
  fieldType: PropTypes.string,
  voyage: PropTypes.shape({
    loadPortId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    dischargePortId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    transhipPortId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  ports: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  onVoyageValueChange: PropTypes.func,
};

const PortOptions = ({ isDisabled, fieldType, voyage, ports, onVoyageValueChange }) => {
  let portsOptions = [];
  let fieldValue;
  let fieldKey;

  switch (fieldType) {
    case 'LOAD': {
      fieldKey = 'loadPortId';
      const port = ports.find(p => p.id === voyage.loadPortId);
      fieldValue = port && { label: port.name, value: port.id };
      break;
    }
    case 'DISCHARGE': {
      fieldKey = 'dischargePortId';
      const port = ports.find(p => p.id === voyage.dischargePortId);
      fieldValue = port && { label: port.name, value: port.id };
      break;
    }
    case 'TRANSHIP': {
      fieldKey = 'transhipPortId';
      const port = ports.find(p => p.id === voyage.transhipPortId);
      fieldValue = port && { label: port.name, value: port.id };
      break;
    }
    default:
      break;
  }

  portsOptions = ports.map(p => ({ label: p.name, value: p.id }));

  return (
    <SelectField
      isClearable
      disabled={isDisabled}
      name="port"
      value={fieldValue}
      defaultValue={fieldValue}
      options={portsOptions}
      onChange={e => onVoyageValueChange(voyage, e ? e.value : null, fieldKey)}
    />
  );
};

PortOptions.propTypes = propTypes;

export default PortOptions;
