import React, { Component } from 'react';
import { Alert } from '../../ui/alert';

class USPurchaseOrderForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Alert status="info" marginTop="30px" marginLeft="10px" fontSize="17px">
        Oops, looks like you’re trying to place a US domestic order. For these, please use the ‘USA Orders’ tab in Order
        Management to complete this transaction.
      </Alert>
    );
  }
}

export default USPurchaseOrderForm;
