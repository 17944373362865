import React, { useEffect, useState } from 'react';
import { Box, VStack } from '@chakra-ui/react';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';

import './OrderActivityLog.scss';
import ContactBedge from '../../quality/importantContacts/ContactBedge';
import api from '../../../api';

const columns = [
  {
    title: 'Edited By',
    dataIndex: 'edited_by',
    render: name => <ContactBedge name={name} />,
  },
  {
    title: 'Edit Type',
    dataIndex: 'edit_type',
  },
  {
    title: 'Edited Field',
    dataIndex: 'edited_field',
  },
  {
    title: 'Old Value',
    dataIndex: 'old_value',
    render: value => (!value || value === 'None' ? 'None' : <Box color="#FF4D49">{value}</Box>),
  },
  {
    title: 'New Value',
    dataIndex: 'new_value',
    render: value => (!value || value === 'None' ? 'None' : <Box color="#4CAF50">{value}</Box>),
  },
  {
    title: 'Entity Id',
    dataIndex: 'entity_id',
  },
];

const OrderActivityLog = ({ grinderPoNumber, entityType }) => {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const getActivitiLogs = async (poNumber, eType) => {
    setIsLoading(true);
    try {
      const response = await api.get(`/order_management/purchase_orders/activity_log/${poNumber}?entity_type=${eType}`);

      const jsonData = await response.json();

      if (jsonData) {
        // group data by date
        const groupedData = jsonData.reduce((acc, curr) => {
          const date = moment(curr.modified_date).format('YYYY-MM-DD');
          if (!acc[date]) {
            acc[date] = [];
          }
          acc[date].push(curr);
          return acc;
        }, {});

        setData(groupedData);
      }
    } catch (error) {
      console.error('Error in getActivitiLogs:- ', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (grinderPoNumber && entityType) {
      getActivitiLogs(grinderPoNumber, entityType);
    }
  }, [grinderPoNumber, entityType]);

  if (isLoading) {
    return (
      <Box padding="30px">
        <Box fontSize="20px" fontWeight="bold" align="center">
          Loading...
        </Box>
      </Box>
    );
  }

  return (
    <>
      {Object.keys(data).length > 0 ? (
        Object.keys(data).map(date => (
          <Box className="order-activity-logs" display="flex" alignItems="flex-start" marginBottom="35px" key={date}>
            <table className="order-activity-logs__date-and-time-table">
              <thead>
                <tr>
                  <th>{date}</th>
                </tr>
              </thead>
              <tbody>
                {data[date].map((item, index) => (
                  <tr key={index}>
                    <td>{moment(item.modified_date).format('hh:mm A')}</td>
                  </tr>
                ))}
              </tbody>
            </table>

            <VStack align="stretch" backgroundColor="card.default" width="100%" overflow="auto">
              <Table
                rowClassName="food-safety-table-row"
                className="food-safety-table"
                pagination={false}
                dataSource={data[date]}
                columns={columns}
              />
            </VStack>
          </Box>
        ))
      ) : (
        <Box padding="30px">
          <Box fontSize="20px" fontWeight="bold" align="center">
            No Activity Logs Found
          </Box>
        </Box>
      )}
    </>
  );
};

OrderActivityLog.propTypes = {
  grinderPoNumber: PropTypes.number,
  entityType: PropTypes.oneOf(['grinder_po_number', 'internal_po_number']),
};

export default OrderActivityLog;
