import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
// Local Deps
import './RejectedCarton.scss';
import SelectField from '../../../../basic/SelectField';
import { Box, HStack, Text, IconButton, Input, Grid } from '@chakra-ui/react';
import CustomFormControl from '../../../../core/CustomFormControl';
import { ReactComponent as TrashOutlineIconSVG } from '../../../../../assets/trash-outline-icon.svg';
import { MdAdd } from 'react-icons/md';
import DateTimeWithTrappedFocus from '../../../../core/DateTimeWithTrappedFocus';
import { Tooltip } from '@/components/ui/tooltip';
import { IoInformationCircleOutline } from 'react-icons/io5';

class RejectedCarton extends Component {
  static propTypes = {
    index: PropTypes.number.isRequired,
    item: PropTypes.instanceOf(Object).isRequired,
    cartonCount: PropTypes.number.isRequired,
    rejectionReasonOptions: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.object]),
        label: PropTypes.string,
      })
    ).isRequired,
    productionDateOptions: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      })
    ).isRequired,
    lineNumberOptions: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        label: PropTypes.string,
      })
    ).isRequired,
    lotNumberOptions: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        label: PropTypes.string,
      })
    ).isRequired,
    packerLotNumberOptions: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        label: PropTypes.string,
      })
    ),
    portMarkOptions: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        label: PropTypes.string,
      })
    ).isRequired,
    onAddCarton: PropTypes.func.isRequired,
    onRemoveCarton: PropTypes.func.isRequired,
    onChangeCarton: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool,
    isLast: PropTypes.bool,
    showToggles: PropTypes.bool,
  };

  handleRemoveCarton = () => {
    const { index, onRemoveCarton } = this.props;
    onRemoveCarton('form', 'rejectedCartons', index);
  };

  render() {
    const {
      index,
      item,
      isLast,
      isDisabled,
      cartonCount,
      rejectionReasonOptions,
      productionDateOptions,
      lineNumberOptions,
      lotNumberOptions,
      packerLotNumberOptions,
      portMarkOptions,
      onChangeCarton,
      onAddCarton,
      showToggles,
    } = this.props;

    const totalCarton = cartonCount + Number(item.numberOfRejectedCartons);

    return (
      <Grid gap="20px" templateColumns="repeat(6, 1fr)" width="100%">
        <SelectField
          width="100%"
          label={
            <>
              Select Product&nbsp;
              <Tooltip
                content="Selects the specific product that the rejected cartons belong to from the dropdown list"
                positioning={{ placement: 'right-end' }}
              >
                <IconButton
                  width="14px"
                  height="14px"
                  padding="0"
                  minW="auto"
                  borderRadius="50%"
                  color="#878787"
                  variant="unstyled"
                >
                  <IoInformationCircleOutline size="14px" />
                </IconButton>
              </Tooltip>
            </>
          }
          disabled={isDisabled}
          name="ingredient"
          defaultValue={item.lineNumber}
          options={lineNumberOptions}
          onChange={e => onChangeCarton('form', 'rejectedCartons', 'lineNumber', index, e ? e.value : null)}
        />

        <SelectField
          width="100%"
          label={
            <>
              Production Date&nbsp;
              <Tooltip
                content="Records the production date of the affected cartons to track and manage production batches."
                positioning={{ placement: 'right-end' }}
              >
                <IconButton
                  width="14px"
                  height="14px"
                  padding="0"
                  minW="auto"
                  borderRadius="50%"
                  color="#878787"
                  variant="unstyled"
                >
                  <IoInformationCircleOutline size="14px" />
                </IconButton>
              </Tooltip>
            </>
          }
          disabled={isDisabled || !item.lineNumber}
          name="productionDate"
          defaultValue={item.productionDate}
          options={productionDateOptions}
          onChange={e => onChangeCarton('form', 'rejectedCartons', 'productionDate', index, e ? e.value : null)}
        />

        <SelectField
          width="100%"
          label={
            <>
              Lot Number&nbsp;
              <Tooltip
                content="Displays the lot number associated with the rejected cartons"
                positioning={{ placement: 'right-end' }}
              >
                <IconButton
                  width="14px"
                  height="14px"
                  padding="0"
                  minW="auto"
                  borderRadius="50%"
                  color="#878787"
                  variant="unstyled"
                >
                  <IoInformationCircleOutline size="14px" />
                </IconButton>
              </Tooltip>
            </>
          }
          disabled={isDisabled}
          name="lotNumber"
          defaultValue={item.lotNumber}
          options={lotNumberOptions}
          onChange={e => onChangeCarton('form', 'rejectedCartons', 'lotNumber', index, e ? e.value : null)}
        />
        {showToggles && (
          <SelectField
            width="100%"
            label={
              <>
                Port Mark&nbsp;
                <Tooltip
                  content="Selects the port mark associated with the rejected cartons from the dropdown list"
                  positioning={{ placement: 'right-end' }}
                >
                  <IconButton
                    width="14px"
                    height="14px"
                    padding="0"
                    minW="auto"
                    borderRadius="50%"
                    color="#878787"
                    variant="unstyled"
                  >
                    <IoInformationCircleOutline size="14px" />
                  </IconButton>
                </Tooltip>
              </>
            }
            disabled={isDisabled}
            name="portMark"
            defaultValue={Number(item.shipmentPortMarkId)}
            options={portMarkOptions}
            onChange={e => onChangeCarton('form', 'rejectedCartons', 'shipmentPortMarkId', index, e ? e.value : null)}
          />
        )}
        {!showToggles && (
          <SelectField
            width="100%"
            label="Packer Lot Number"
            disabled={isDisabled}
            name="lotNumber"
            defaultValue={item.packerLotNumber}
            options={packerLotNumberOptions}
            onChange={e => onChangeCarton('form', 'rejectedCartons', 'packerLotNumber', index, e ? e.value : null)}
          />
        )}

        <SelectField
          width="100%"
          label={
            <>
              Reason for Rejection&nbsp;
              <Tooltip
                content="Selects the reason why the cartons were rejected from the dropdown list"
                positioning={{ placement: 'right-end' }}
              >
                <IconButton
                  width="14px"
                  height="14px"
                  padding="0"
                  minW="auto"
                  borderRadius="50%"
                  color="#878787"
                  variant="unstyled"
                >
                  <IoInformationCircleOutline size="14px" />
                </IconButton>
              </Tooltip>
            </>
          }
          disabled={isDisabled}
          name="reason-for-rejection"
          defaultValue={item.reasonForRejection}
          options={rejectionReasonOptions}
          onChange={e => onChangeCarton('form', 'rejectedCartons', 'reasonForRejection', index, e ? e.value : null)}
        />
        <CustomFormControl
          width="100%"
          label={
            <>
              Rejection Date&nbsp;
              <Tooltip
                content="Records the date when the cartons were rejected to mark when the issue was identified."
                positioning={{ placement: 'right-end' }}
              >
                <IconButton
                  width="14px"
                  height="14px"
                  padding="0"
                  minW="auto"
                  borderRadius="50%"
                  color="#878787"
                  variant="unstyled"
                >
                  <IoInformationCircleOutline size="14px" />
                </IconButton>
              </Tooltip>
            </>
          }
        >
          <DateTimeWithTrappedFocus
            id="rejection-date"
            inputProps={{
              className: 'form-control m-input',
              disabled: isDisabled,
            }}
            closeOnSelect
            value={item.rejectionDate}
            onChange={e =>
              onChangeCarton(
                'form',
                'rejectedCartons',
                'rejectionDate',
                index,
                e._isAMomentObject ? e.format('YYYY-MM-DD') : e // eslint-disable-line no-underscore-dangle
              )
            }
            timeFormat={false}
            dateFormat="YYYY-MM-DD"
          />
        </CustomFormControl>
        <CustomFormControl
          width="100%"
          label={
            <>
              Number of Cartons {cartonCount}&nbsp;
              <Tooltip
                content="Records the quantity of cartons that were rejected to quantify the extent of the issue"
                positioning={{ placement: 'right-end' }}
              >
                <IconButton
                  width="14px"
                  height="14px"
                  padding="0"
                  minW="auto"
                  borderRadius="50%"
                  color="#878787"
                  variant="unstyled"
                >
                  <IoInformationCircleOutline size="14px" />
                </IconButton>
              </Tooltip>
            </>
          }
        >
          <Input
            backgroundColor="white"
            name="num-of-rejected-cartons"
            type="number"
            min="0"
            max={totalCarton}
            value={item.numberOfRejectedCartons}
            disabled={isDisabled}
            className="form-control"
            onChange={e => {
              const value = get(e, 'target.value');
              // if for some reason max doesn't work on a browser
              // (max didn't work while testing locally initially)
              if (value <= totalCarton) {
                onChangeCarton('form', 'rejectedCartons', 'numberOfRejectedCartons', index, value);
              }
            }}
            onWheelCapture={e => {
              e.target.blur();
            }}
          />
        </CustomFormControl>
        {isLast && (
          <Box height="100%" width="100%">
            {/* <!-- TOTAL REJECTED CARTONS --> */}
            <Text as="p" fontWeight="medium">
              Available Cartons for Product
            </Text>
            <Text as="p" fontWeight="medium" marginTop="14px" marginLeft="12px">
              {cartonCount}
            </Text>
          </Box>
        )}
        {!isDisabled && (
          <HStack gap="5px" marginLeft="auto !important" alignItems="flex-end">
            {isLast && (
              <IconButton
                colorScheme="actionSecondary"
                onClick={onAddCarton}
                disabled={isDisabled}
                hidden={isDisabled}
                height="25px"
                minWidth="25px"
                borderRadius="14px"
              >
                <MdAdd />
              </IconButton>
            )}
            <IconButton
              variant="ghost"
              bg="inherit"
              padding={1}
              disabled={isDisabled}
              onClick={() => this.handleRemoveCarton(index)}
              height="25px"
              width="25px"
              fontSize="25px"
            >
              <TrashOutlineIconSVG />
            </IconButton>
          </HStack>
        )}
      </Grid>
    );
  }
}

export default RejectedCarton;
