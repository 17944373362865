import React, { useState } from 'react';
import PropTypes from 'prop-types';

import VoyageDetailsItem from '../../../../containers/orders/VoyageDetailsItem';
import './VoyageDetails.scss';
import { Box, Button, IconButton, Input, Text, VStack, Flex, Grid } from '@chakra-ui/react';
import CustomFormControl from '../../../core/CustomFormControl';
import { Tooltip } from '@/components/ui/tooltip';
import { IoInformationCircleOutline } from 'react-icons/io5';

const propTypes = {
  deleteVoyage: PropTypes.func,
  editMode: PropTypes.bool,
  exportDetails: PropTypes.shape({
    healthCertificateNumber: PropTypes.string,
    isf: PropTypes.string,
    permitNumber: PropTypes.string,
  }),
  finalDestination: PropTypes.string,
  isISFRequired: PropTypes.bool,
  isUsCustomsNumberCfc: PropTypes.string,
  isPermitNumberRequired: PropTypes.bool,
  onChangeValue: PropTypes.func,
  onObjectValueChange: PropTypes.func,
  onVoyageValueChange: PropTypes.func,
  ports: PropTypes.array,
  shippingLines: PropTypes.array,
  validationErrors: PropTypes.instanceOf(Object),
  voyage: PropTypes.instanceOf(Object),
  documents: PropTypes.arrayOf(PropTypes.object),
};

const VoyageDetails = ({
  deleteVoyage,
  editMode,
  exportDetails,
  finalDestination,
  isISFRequired,
  isUsCustomsNumberCfc,
  isPermitNumberRequired,
  onChangeValue,
  onObjectValueChange,
  onVoyageValueChange,
  ports,
  shippingLines,
  validationErrors,
  voyage,
  documents,
}) => {
  const [state, setState] = useState({
    areAllExpanded: false,
  });
  const shippingLineOptions = shippingLines.map(s => ({ label: s.name, value: s.id }));
  const portsOptions = ports.map(p => ({ label: p.name, value: p.id }));

  /* checks if voyage has at least one key with value
       if none of the voyage keys have values then it is considered to be empty 
    */
  const isVoyageEmpty = !Object.values(voyage).some(v => v !== null && v !== '');
  const isVoyageRemoved = editMode ? isVoyageEmpty : false;

  return (
    <VStack gap="24px" align="stretch">
      <Grid gap="20px" templateColumns="repeat(4, 1fr)" width="100%">
        <CustomFormControl
          width="100%"
          label={
            <>
              Final Destination&nbsp;
              <Tooltip content="Specifies the final delivery location for the shipment." positioning={{ placement: 'right-end' }}>
                <IconButton
                  width="14px"
                  height="14px"
                  padding="0"
                  minW="auto"
                  borderRadius="50%"
                  color="#878787"
                  variant="unstyled"
                >
                  <IoInformationCircleOutline size="14px" />
                </IconButton>
              </Tooltip>
            </>
          }
        >
          <Input
            backgroundColor="white"
            name="finalDestination"
            value={finalDestination || ''}
            disabled={!editMode}
            className="form-control"
            onChange={e => onChangeValue('finalDestination', e.target.value)}
          />
        </CustomFormControl>
        <CustomFormControl
          width="305px"
          label={
            <>
              Health Certificate Number&nbsp;
              <Tooltip content="Provides the official health certificate number." positioning={{ placement: 'right-end' }}>
                <IconButton
                  width="14px"
                  height="14px"
                  padding="0"
                  minW="auto"
                  borderRadius="50%"
                  color="#878787"
                  variant="unstyled"
                >
                  <IoInformationCircleOutline size="14px" />
                </IconButton>
              </Tooltip>
            </>
          }
        >
          <Input
            backgroundColor="white"
            name="healthCertificateNumber"
            value={exportDetails?.healthCertificateNumber || ''}
            disabled={!editMode}
            className="form-control"
            onChange={e => onObjectValueChange('exportDetails', 'healthCertificateNumber', e.target.value || '')}
          />
        </CustomFormControl>
        {isUsCustomsNumberCfc && (
          <CustomFormControl
            width="305px"
            label={
              <>
                US Customs Entry Number&nbsp;
                <Tooltip content="Provides the official health certificate number." positioning={{ placement: 'right-end' }}>
                  <IconButton
                    width="14px"
                    height="14px"
                    padding="0"
                    minW="auto"
                    borderRadius="50%"
                    color="#878787"
                    variant="unstyled"
                  >
                    <IoInformationCircleOutline size="14px" />
                  </IconButton>
                </Tooltip>
              </>
            }
          >
            <Input
              backgroundColor="white"
              name="usCustomsEntryNumber"
              value={exportDetails?.usCustomsEntryNumber || ''}
              disabled={!editMode}
              className="form-control"
              onChange={e => onObjectValueChange('exportDetails', 'usCustomsEntryNumber', e.target.value || '')}
            />
          </CustomFormControl>
        )}
        {isISFRequired && (
          <CustomFormControl
            width="305px"
            label={
              <>
                ISF&nbsp;
                <Tooltip content="Provides the Importer Security Filing (ISF) information." positioning={{ placement: 'right-end' }}>
                  <IconButton
                    width="14px"
                    height="14px"
                    padding="0"
                    minW="auto"
                    borderRadius="50%"
                    color="#878787"
                    variant="unstyled"
                  >
                    <IoInformationCircleOutline size="14px" />
                  </IconButton>
                </Tooltip>
              </>
            }
          >
            <Input
              backgroundColor="white"
              name="isf"
              value={exportDetails?.isf || ''}
              disabled={!editMode}
              className="form-control"
              onChange={e => onObjectValueChange('exportDetails', 'isf', e.target.value || '')}
            />
          </CustomFormControl>
        )}
        {isPermitNumberRequired && (
          <CustomFormControl width="305px" label="Permit Number">
            <Input
              name="permitNumber"
              value={exportDetails?.permitNumber || ''}
              disabled={!editMode}
              backgroundColor="white"
              className="form-control"
              onChange={e => onObjectValueChange('exportDetails', 'permitNumber', e.target.value || '')}
            />
          </CustomFormControl>
        )}
      </Grid>
      <Box>
        <VStack gap="11px" align="stretch">
          <VoyageDetailsItem
            editMode={editMode}
            voyage={voyage}
            onVoyageValueChange={onVoyageValueChange}
            shippingLineOptions={shippingLineOptions}
            portsOptions={portsOptions}
            ports={ports}
            validationErrors={validationErrors}
            onExpansionChange={value => setState({ ...state, areAllExpanded: value })}
            documents={documents}
          />
        </VStack>
        {state.areAllExpanded && (
          <Button
            onClick={deleteVoyage}
            hidden={!editMode || isVoyageRemoved}
            colorScheme="actionPrimary"
            disabled={!editMode}
            float="right"
          >
            <Text as="p">Delete Voyage</Text>
          </Button>
        )}
      </Box>
    </VStack>
  );
};

VoyageDetails.propTypes = propTypes;

export default VoyageDetails;
