import { Box, Grid, GridItem, Text, VStack } from '@chakra-ui/react';
import { isEqual, isNumber, isString } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { convertToCamelCase, splitCamelCaseString, splitSnakeCaseString } from '../../../../helpers';
import { formatNumber, getInternalPoLabel } from '../../../../utils';
import './OrderDetails.scss';

const mapStateToProps = state => {
  const result = {
    configs: state.configs,
  };
  const cameled = convertToCamelCase(result);
  return cameled;
};

const DetailGridItem = connect(mapStateToProps)(
  React.memo(({ detail, value, configs }) => {
    const internalPoLabel = getInternalPoLabel(configs)?.split(' ', 1);
    let detailText = splitCamelCaseString(detail);
    const pattern = /fmg/gi;
    if (internalPoLabel && detailText.startsWith(pattern.source)) {
      detailText = detailText.replace(pattern, internalPoLabel[0]);
    }

    return (
      <GridItem colSpan={2}>
        <Box wordBreak="break-all">
          <Text as="p" textTransform="capitalize">
            {detailText}
          </Text>
          <Text as="p" fontWeight="bold">
            {(() => {
              if (isString(value)) {
                return splitSnakeCaseString(value);
              } else if (isNumber(value)) {
                return formatNumber(value);
              }
              return value;
            })()}
          </Text>
        </Box>
      </GridItem>
    );
  }, isEqual)
);

DetailGridItem.propTypes = {
  detail: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

class OrderDetails extends PureComponent {
  constructor(props) {
    super(props);
  }

  static propTypes = {
    currentOrder: PropTypes.shape({
      lines: PropTypes.array,
      orderDate: PropTypes.string,
    }),
    user: PropTypes.shape({
      organisation: PropTypes.shape({
        orgType: PropTypes.string,
      }),
    }),
  };

  shouldOmmitLineProperty(detail) {
    const { user } = this.props;
    return (
      user?.organisation?.orgType?.indexOf('grinder') > -1 &&
      (detail.indexOf('cost') > -1 || detail.indexOf('price') > -1)
    );
  }

  shouldOmmitProperty(detail) {
    const { user } = this.props;
    return (
      (user?.organisation?.orgType?.indexOf('grinder') > -1 &&
        (detail.indexOf('cost') > -1 || detail.indexOf('price') > -1)) ||
      user?.organisation?.orgType?.indexOf('packer') > -1 ||
      detail.indexOf('documents') > -1
    );
  }

  render() {
    const allOrderDetails = [];
    const { currentOrder } = this.props;

    if (currentOrder.orderDate) {
      currentOrder.orderDate = moment(currentOrder.orderDate).format('YYYY-MM-DD');
    }

    Object.keys(currentOrder).forEach((detail, index) => {
      if (this.shouldOmmitProperty(detail)) {
        return;
      }

      if (detail !== 'lines') {
        if (typeof currentOrder[detail] !== 'object') {
          allOrderDetails.push(
            <DetailGridItem key={index} value={currentOrder[detail] ? currentOrder[detail] : ' - '} detail={detail} />
          );
        }
      }
    });

    return (
      <VStack align="stretch" gap="60px">
        <Grid templateColumns="repeat(12, 1fr)" gap="20px">
          {allOrderDetails.map(detail => {
            return detail;
          })}
        </Grid>
        <VStack align="stretch" gap="20px">
          <Text as="p" fontSize="16px" fontWeight="bold">
            Line Details
          </Text>
          <VStack align="stretch" gap="50px">
            {currentOrder.lines.map((item, index) => {
              const allLineDetails = [];
              Object.keys(item).forEach((detail, detailIndex) => {
                if (this.shouldOmmitLineProperty(detail)) {
                  return;
                }

                if (typeof item[detail] !== 'object') {
                  allLineDetails.push(
                    <DetailGridItem key={detailIndex} value={item[detail] ? item[detail] : ' - '} detail={detail} />
                  );
                }
              });
              return (
                <GridItem colSpan={12} key={index}>
                  <Text as="p" fontWeight="bold">
                    Purchase order line #{index + 1}
                  </Text>

                  <Grid templateColumns="repeat(12, 1fr)" gap="25px">
                    {allLineDetails.map(detail => {
                      return detail;
                    })}
                  </Grid>
                </GridItem>
              );
            })}
          </VStack>
        </VStack>
      </VStack>
    );
  }
}

export default OrderDetails;
