import React from 'react';
import PropTypes from 'prop-types';

import SelectField from '../../../basic/SelectField';
import { Box, IconButton, Input, Table, Text } from '@chakra-ui/react';
import { MdAdd } from 'react-icons/md';
import { ReactComponent as TrashOutlineIconSVG } from '../../../../assets/trash-outline-icon.svg';
import { Tooltip } from '@/components/ui/tooltip';
import { IoInformationCircleOutline } from 'react-icons/io5';

const propTypes = {
  editMode: PropTypes.bool,
  form: PropTypes.shape({
    lines: PropTypes.arrayOf(PropTypes.object),
  }),
  handleChangeObjectList: PropTypes.func,
  handleRemoveObjectListItem: PropTypes.func,
  index: PropTypes.number,
  item: PropTypes.shape({
    id: PropTypes.number,
    purchaseOrderLineIds: PropTypes.arrayOf(PropTypes.number),
    numberOfCartons: PropTypes.number,
    mark: PropTypes.string,
  }),
  onDeleteMark: PropTypes.func,
  onAddMark: PropTypes.func,
  count: PropTypes.number,
};
const ShipmentPortMark = props => {
  const { count, onAddMark } = props;

  const removeMark = () => {
    if (props.item.id) {
      const confirmation = window.confirm('Are you sure you want to remove mark?');

      if (confirmation) {
        props.onDeleteMark(props.item);
      }
    } else {
      props.handleRemoveObjectListItem('billOfLading', 'shipmentPortMarks', props.index);
    }
  };

  const { handleChangeObjectList, index, item, editMode, form } = props;
  const lineOptions = form.lines
    .filter(line => {
      if (!line.id) {
        return false;
      }
      if (!line.active) {
        return false;
      }
      return true;
    })
    .map(line => ({ label: `PO Line #${line.lineNumber}`, value: line.id }));
  const lineValue = item.purchaseOrderLineIds;
  return (
    <Box overflowX="visible" overflowY="visible">
      <Table.Root variant="unstyled" width="fit-content">
        <Table.Header>
          <Table.Row>
            <Table.ColumnHeader paddingX="0px" paddingBottom="0px" />
            <Table.ColumnHeader paddingLeft="5px" paddingBottom="0px">
              <Text as="p" fontSize="14px" fontWeight="medium" textTransform="capitalize">
                Shipment/Port Mark&nbsp;
                <Tooltip
                  content="Specifies the shipment or port mark, which serves as an identifier or label for tracking."
                  positioning={{ placement: 'right-end' }}
                >
                  <IconButton
                    width="14px"
                    height="14px"
                    padding="0"
                    minW="auto"
                    borderRadius="50%"
                    color="#878787"
                    variant="unstyled"
                  >
                    <IoInformationCircleOutline size="14px" />
                  </IconButton>
                </Tooltip>
              </Text>
            </Table.ColumnHeader>
            <Table.ColumnHeader paddingBottom="0px">
              <Text as="p" fontSize="14px" fontWeight="medium" textTransform="capitalize">
                Cartons&nbsp;
                <Tooltip
                  content="Indicates the number of cartons in the shipment to facilitate inventory management"
                  positioning={{ placement: 'right-end' }}
                >
                  <IconButton
                    width="14px"
                    height="14px"
                    padding="0"
                    minW="auto"
                    borderRadius="50%"
                    color="#878787"
                    variant="unstyled"
                  >
                    <IoInformationCircleOutline size="14px" />
                  </IconButton>
                </Tooltip>
              </Text>
            </Table.ColumnHeader>
            <Table.ColumnHeader paddingBottom="0px">
              <Text as="p" fontSize="14px" fontWeight="medium" textTransform="capitalize">
                Purchase Order Line&nbsp;
                <Tooltip
                  content="Selects the specific purchase order line related to the shipment from the dropdown list"
                  positioning={{ placement: 'right-end' }}
                >
                  <IconButton
                    width="14px"
                    height="14px"
                    padding="0"
                    minW="auto"
                    borderRadius="50%"
                    color="#878787"
                    variant="unstyled"
                  >
                    <IoInformationCircleOutline size="14px" />
                  </IconButton>
                </Tooltip>
              </Text>
            </Table.ColumnHeader>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell fontWeight="medium" color="accent.one.default" paddingX="0px">
              {index + 1}
            </Table.Cell>
            <Table.Cell paddingLeft="5px" width="305px">
              <Input
                name="shipmentPortMark"
                value={item.mark}
                placeholder="Shipment/Port Mark"
                disabled={!editMode}
                backgroundColor="white"
                className="form-control"
                onChange={e =>
                  handleChangeObjectList('billOfLading', 'shipmentPortMarks', 'mark', index, e.target.value, true)
                }
              />
            </Table.Cell>
            <Table.Cell width="305px">
              <Input
                name="numberOfCartons"
                type="number"
                value={item.numberOfCartons || ''}
                placeholder="Cartons"
                disabled={!editMode}
                backgroundColor="white"
                className="form-control"
                onChange={e =>
                  handleChangeObjectList(
                    'billOfLading',
                    'shipmentPortMarks',
                    'numberOfCartons',
                    index,
                    e.target.value,
                    true
                  )
                }
                onWheelCapture={e => {
                  e.target.blur();
                }}
              />
            </Table.Cell>
            <Table.Cell width="305px">
              <SelectField
                disabled={!editMode}
                name="portmarkPurchaseOrderLine"
                defaultValue={lineValue}
                options={lineOptions}
                isMulti
                onChange={e =>
                  handleChangeObjectList(
                    'billOfLading',
                    'shipmentPortMarks',
                    'purchaseOrderLineIds',
                    index,
                    e.map(itm => itm.value),
                    false
                  )
                }
              />
            </Table.Cell>
            {editMode && (
              <Table.Cell padding="0px">
                <IconButton
                  onClick={removeMark}
                  variant="ghost"
                  disabled={!editMode}
                  height="25px"
                  width="25px"
                  fontSize="25px"
                >
                  <TrashOutlineIconSVG />
                </IconButton>
              </Table.Cell>
            )}
            {editMode && index + 1 === count && (
              <Table.Cell padding="0px">
                <IconButton
                  onClick={onAddMark}
                  colorScheme="actionSecondary"
                  variant="solid"
                  disabled={!editMode}
                  height="25px"
                  minWidth="25px"
                  borderRadius="14px"
                >
                  <MdAdd />
                </IconButton>
              </Table.Cell>
            )}
          </Table.Row>
        </Table.Body>
      </Table.Root>
    </Box>
  );
};

ShipmentPortMark.propTypes = propTypes;

export default ShipmentPortMark;
