import React from 'react';
import PropTypes from 'prop-types';
import Datetime from '@/components/core/DatePicker';
import { Box, HStack, Input, VStack } from '@chakra-ui/react';
import CustomFormControl from '../../core/CustomFormControl';
import { getOr } from '../../../helpers';

const propTypes = {
  form: PropTypes.shape({
    ordersPerDayOfWeek: PropTypes.arrayOf(PropTypes.number),
    recurringDateStart: PropTypes.string,
    recurringDateEnd: PropTypes.string,
  }),
  onRecurringValueChange: PropTypes.func,
  requiredFields: PropTypes.shape({
    form: PropTypes.arrayOf(PropTypes.string),
  }),
};

const RecurringSection = ({ form, onRecurringValueChange, requiredFields }) => {
  const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  const recurringDateStartInputProps = {
    placeholder: 'Recurring Start',
    className: 'form-control m-input',
  };
  const recurringDateEndInputProps = {
    placeholder: 'Recurring End',
    className: 'form-control m-input',
  };

  const formValues = daysOfWeek.map((day, index) => {
    return (
      <Box key={`recurring-${index}`} width="50px">
        {day}
        <br />
        <Input
          className="form-control"
          value={form && form.ordersPerDayOfWeek && form.ordersPerDayOfWeek[index]}
          onChange={e => onRecurringValueChange('ordersPerDayOfWeek', e ? e.target : null, index)}
        />
      </Box>
    );
  });

  const requiredFieldsForm = getOr(requiredFields, 'form', []);

  return (
    <VStack spacing="25px" align="stretch" marginTop="32px">
      <HStack spacing="29px">
        <CustomFormControl label="Recurring Start Date">
          <Datetime
            id="recurringDateStart"
            inputProps={recurringDateStartInputProps}
            closeOnSelect
            value={form.recurringDateStart}
            className={requiredFieldsForm.includes('recurringDateStart') ? 'error' : ''}
            onChange={e =>
              // eslint-disable-next-line no-underscore-dangle
              onRecurringValueChange('recurringDateStart', e._isAMomentObject ? e.format('YYYY-MM-DD') : e)
            }
            timeFormat={false}
            dateFormat="YYYY-MM-DD"
          />
        </CustomFormControl>
        <CustomFormControl label="Recurring End Date">
          <Datetime
            id="recurringDateEnd"
            inputProps={recurringDateEndInputProps}
            closeOnSelect
            className={requiredFieldsForm.includes('recurringDateEnd') ? 'error' : ''}
            value={form.recurringDateEnd}
            onChange={e =>
              // eslint-disable-next-line no-underscore-dangle
              onRecurringValueChange('recurringDateEnd', e._isAMomentObject ? e.format('YYYY-MM-DD') : e)
            }
            dateFormat="YYYY-MM-DD"
          />
        </CustomFormControl>
      </HStack>
      <HStack>
        <CustomFormControl label="Recurring Days">
          <HStack>{formValues}</HStack>
        </CustomFormControl>
      </HStack>
    </VStack>
  );
};

RecurringSection.propTypes = propTypes;

export default RecurringSection;
