import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SelectField from '../../../basic/SelectField';
import { HStack, IconButton, Text, VStack, Grid } from '@chakra-ui/react';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa';
import DateTimeWithTrappedFocus from '../../../core/DateTimeWithTrappedFocus';
import { Field } from '@/components/ui/field';

const propTypes = {
  containerDepots: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string })),
  containerReturns: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string })),
  editMode: PropTypes.bool,
  form: PropTypes.shape(),
  onObjectValueChange: PropTypes.func,
};

const FOBInformation = ({ form, onObjectValueChange, editMode, containerDepots, containerReturns }) => {
  const [state, setState] = useState({
    fobIsExpanded: true,
  });
  const containerDepotOptions = containerDepots.map(el => ({ value: el.value, label: el.value }));
  const containerReturnOptions = containerReturns.map(el => ({ value: el.value, label: el.value }));

  return (
    <VStack spacing="11px" align="stretch">
      <HStack justifyContent="space-between" color="accent.one.default" width="150px">
        <Text as="p" fontWeight="medium" color="inherit">
          FOB Information
        </Text>
        <IconButton
          variant="unstyled"
          minWidth="18px"
          height="fit-content"
          padding="0px"
          fontSize="12px"
          onClick={() => setState({ ...state, fobIsExpanded: !state.fobIsExpanded })}
        >
          {state.fobIsExpanded ? <FaChevronDown /> : <FaChevronRight />}
        </IconButton>
      </HStack>
      {state.fobIsExpanded && (
        <Grid gap="20px" templateColumns="repeat(4, 1fr)" width="100%">
          <SelectField
            width="100%"
            label="Container Depot"
            isClearable
            disabled={!editMode}
            name="containerDepot"
            defaultValue={form.exportDetails.containerDepot || ''}
            options={containerDepotOptions}
            onChange={e => onObjectValueChange('exportDetails', 'containerDepot', e ? e.value : '')}
          />

          <SelectField
            width="100%"
            label="Container Return"
            isClearable
            disabled={!editMode}
            name="containerReturn"
            defaultValue={form.exportDetails.containerReturn || ''}
            options={containerReturnOptions}
            onChange={e => onObjectValueChange('exportDetails', 'containerReturn', e ? e.value : '')}
          />

          <Field label="Container Gate in Date" width="100%">
            <DateTimeWithTrappedFocus
              width="100%"
              label="Container Gate in Date"
              id="container-gate-in-date"
              inputProps={{
                className: 'form-control m-input',
                disabled: !editMode,
              }}
              closeOnSelect
              value={form.exportDetails.containerGateInDate || null}
              onChange={e => {
                return onObjectValueChange(
                  'exportDetails',
                  'containerGateInDate',
                  e._isAMomentObject ? e.format('YYYY-MM-DD') : e // eslint-disable-line no-underscore-dangle
                );
              }}
              timeFormat={false}
              dateFormat="YYYY-MM-DD"
            />
          </Field>
        </Grid>
      )}
    </VStack>
  );
};

FOBInformation.propTypes = propTypes;

export default FOBInformation;
