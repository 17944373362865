import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@chakra-ui/react';
import { withInternationalOrderFormContext } from '../InternationalOrderForm/InternationalOrderFormContext';
import { isEqual } from 'lodash';
import { defaultRequiredSydneyPOFormLabels } from '../../../lib/PurchaseOrderFunctions';
import { Tooltip } from '@/components/ui/tooltip';
import { IoInformationCircleOutline } from 'react-icons/io5';

const POFormFieldLabel = ({ htmlFor, label, fieldName, isRequired, requiredFieldsLabels, tooltip }) => {
  return (
    <label htmlFor={htmlFor}>
      {label}
      {tooltip !== undefined && (
        <>
          &nbsp;
          <Tooltip content={tooltip} positioning={{ placement: 'right-end' }}>
            <IconButton
              width="14px"
              height="14px"
              padding="0"
              minW="auto"
              borderRadius="50%"
              color="#878787"
              variant="unstyled"
            >
              <IoInformationCircleOutline size="14px" />
            </IconButton>
          </Tooltip>
        </>
      )}
      {fieldName &&
      (isRequired ||
        requiredFieldsLabels?.form?.includes(fieldName) ||
        defaultRequiredSydneyPOFormLabels.includes(fieldName))
        ? '*'
        : ''}
    </label>
  );
};

POFormFieldLabel.propTypes = {
  htmlFor: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  fieldName: PropTypes.string,
  isRequired: PropTypes.bool,
  requiredFieldsLabels: PropTypes.arrayOf(PropTypes.string),
  tooltip: PropTypes.string,
};

export default withInternationalOrderFormContext(React.memo(POFormFieldLabel, isEqual));
