import React, { useState } from 'react';
import PropTypes from 'prop-types';
// Local
import SelectField from '../../../basic/SelectField';
import ShipmentPortMark from '../../../../containers/orders/BillOfLading';
import { Box, Input, Text, VStack, Button, HStack, IconButton, Flex, Grid } from '@chakra-ui/react';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa';
import CustomFormControl from '../../../core/CustomFormControl';
import DateTimeWithTrappedFocus from '../../../core/DateTimeWithTrappedFocus';
import { Tooltip } from '@/components/ui/tooltip';
import { IoInformationCircleOutline } from 'react-icons/io5';

const propTypes = {
  addMark: PropTypes.func,
  containers: PropTypes.arrayOf(PropTypes.object),
  deleteMark: PropTypes.func,
  editMode: PropTypes.bool,
  form: PropTypes.shape({
    billOfLading: PropTypes.shape({
      bolNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      bookingReferenceNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      containerNumber: PropTypes.string,
      containerTemperature: PropTypes.string,
      sealNumber: PropTypes.string,
      exportDeclarationNumber: PropTypes.string,
      grossShippedWeight: PropTypes.string,
      shipmentPortMarks: PropTypes.arrayOf(PropTypes.shape({})),
      bolDate: PropTypes.Datetime,
    }),
    loadContainerDetails: PropTypes.string,
  }),
  handleChangeObjectList: PropTypes.func,
  handleGrossShippedWeightBlur: PropTypes.func,
  handleGrossShippedWeightChange: PropTypes.func,
  handleRemoveObjectListItem: PropTypes.func,
  onObjectValueChange: PropTypes.func,
};

const BillOfLading = ({
  addMark,
  containers,
  deleteMark,
  editMode,
  form,
  handleChangeObjectList,
  handleGrossShippedWeightBlur,
  handleGrossShippedWeightChange,
  handleRemoveObjectListItem,
  onObjectValueChange,
}) => {
  const [state, setState] = useState({
    billOfLadingIsExpanded: true,
    shipmentPortIsExpanded: true,
  });

  const containerOptions = containers.map(el => ({ value: el.value, label: el.value }));

  return (
    <VStack gap="27px" align="stretch">
      <VStack gap="11px" align="stretch">
        <HStack justifyContent="space-between" color="accent.one.default" width="150px">
          <Text as="p" fontWeight="medium" color="inherit">
            Bill Of Lading
          </Text>
          <IconButton
            variant="unstyled"
            minWidth="18px"
            height="fit-content"
            padding="0px"
            fontSize="12px"
            onClick={() => setState({ ...state, billOfLadingIsExpanded: !state.billOfLadingIsExpanded })}
          >
            {state.billOfLadingIsExpanded ? <FaChevronDown /> : <FaChevronRight />}
          </IconButton>
        </HStack>
        {state.billOfLadingIsExpanded && (
          <VStack gap="25px" align="stretch">
            <Grid gap="20px" templateColumns="repeat(4, 1fr)" width="100%">
              <CustomFormControl
                width="100%"
                label={
                  <>
                    BOL Number&nbsp;
                    <Tooltip
                      content="The Bill of Lading (BOL) is a legal document issued by a carrier to acknowledge receipt of cargo."
                      positioning={{ placement: 'right-end' }}
                    >
                      <IconButton
                        width="14px"
                        height="14px"
                        padding="0"
                        minW="auto"
                        borderRadius="50%"
                        color="#878787"
                        variant="unstyled"
                      >
                        <IoInformationCircleOutline size="14px" />
                      </IconButton>
                    </Tooltip>
                  </>
                }
              >
                <Input
                  backgroundColor="white"
                  name="bolNumber"
                  value={form?.billOfLading?.bolNumber || ''}
                  disabled={!editMode}
                  className="form-control"
                  onChange={e => onObjectValueChange('billOfLading', 'bolNumber', e.target.value)}
                />
              </CustomFormControl>
              <CustomFormControl
                width="100%"
                label={
                  <>
                    BOL Date&nbsp;
                    <Tooltip content="Provides the date when the Bill of Lading (BOL) was issued." positioning={{ placement: 'right-end' }}>
                      <IconButton
                        width="14px"
                        height="14px"
                        padding="0"
                        minW="auto"
                        borderRadius="50%"
                        color="#878787"
                        variant="unstyled"
                      >
                        <IoInformationCircleOutline size="14px" />
                      </IconButton>
                    </Tooltip>
                  </>
                }
              >
                <DateTimeWithTrappedFocus
                  id="bol-date"
                  inputProps={{ ...(form?.billOfLading?.bolDate || null), disabled: !editMode }}
                  closeOnSelect
                  value={form?.billOfLading?.bolDate}
                  /* eslint-disable-next-line no-underscore-dangle */
                  onChange={e =>
                    onObjectValueChange('billOfLading', 'bolDate', e._isAMomentObject ? e.format('YYYY-MM-DD') : e)
                  }
                  timeFormat={false}
                  dateFormat="YYYY-MM-DD"
                />
              </CustomFormControl>
              <CustomFormControl
                width="100%"
                label={
                  <>
                    Booking Reference Number&nbsp;
                    <Tooltip
                      content="The Booking Reference Number is a unique identifier provided by the shipping line to track."
                      positioning={{ placement: 'right-end' }}
                    >
                      <IconButton
                        width="14px"
                        height="14px"
                        padding="0"
                        minW="auto"
                        borderRadius="50%"
                        color="#878787"
                        variant="unstyled"
                      >
                        <IoInformationCircleOutline size="14px" />
                      </IconButton>
                    </Tooltip>
                  </>
                }
              >
                <Input
                  backgroundColor="white"
                  name="booking-reference-number"
                  value={form?.billOfLading?.bookingReferenceNumber || ''}
                  disabled={!editMode}
                  className="form-control"
                  onChange={e => onObjectValueChange('billOfLading', 'bookingReferenceNumber', e.target.value)}
                />
              </CustomFormControl>
            </Grid>
            <Grid gap="20px" templateColumns="repeat(4, 1fr)" width="100%">
              <SelectField
                width="100%"
                label={
                  <>
                    Container Type&nbsp;
                    <Tooltip content="Specifies the type of container used for shipping." positioning={{ placement: 'right-end' }}>
                      <IconButton
                        width="14px"
                        height="14px"
                        padding="0"
                        minW="auto"
                        borderRadius="50%"
                        color="#878787"
                        variant="unstyled"
                      >
                        <IoInformationCircleOutline size="14px" />
                      </IconButton>
                    </Tooltip>
                  </>
                }
                disabled={!editMode}
                name="loadContainerDetails"
                defaultValue={form?.loadContainerDetails || ''}
                options={containerOptions}
                onChange={e => onObjectValueChange(null, 'loadContainerDetails', e ? e.value : '')}
              />
              <CustomFormControl
                width="100%"
                label={
                  <>
                    Container Number&nbsp;
                    <Tooltip content="Provides the unique container number." positioning={{ placement: 'right-end' }}>
                      <IconButton
                        width="14px"
                        height="14px"
                        padding="0"
                        minW="auto"
                        borderRadius="50%"
                        color="#878787"
                        variant="unstyled"
                      >
                        <IoInformationCircleOutline size="14px" />
                      </IconButton>
                    </Tooltip>
                  </>
                }
              >
                <Input
                  backgroundColor="white"
                  name="container-number"
                  value={form?.billOfLading?.containerNumber || ''}
                  disabled={!editMode}
                  className="form-control"
                  onChange={e => onObjectValueChange('billOfLading', 'containerNumber', e.target.value)}
                />
              </CustomFormControl>
              <CustomFormControl
                width="100%"
                label={
                  <>
                    Container Temperature&nbsp;
                    <Tooltip content="Specifies the required temperature for the container." positioning={{ placement: 'right-end' }}>
                      <IconButton
                        width="14px"
                        height="14px"
                        padding="0"
                        minW="auto"
                        borderRadius="50%"
                        color="#878787"
                        variant="unstyled"
                      >
                        <IoInformationCircleOutline size="14px" />
                      </IconButton>
                    </Tooltip>
                  </>
                }
              >
                <Input
                  backgroundColor="white"
                  name="container-temperature"
                  value={form?.billOfLading?.containerTemperature || ''}
                  disabled={!editMode}
                  className="form-control"
                  onChange={e => onObjectValueChange('billOfLading', 'containerTemperature', e.target.value)}
                />
              </CustomFormControl>
            </Grid>
            <Grid gap="20px" templateColumns="repeat(4, 1fr)" width="100%">
              <CustomFormControl
                width="100%"
                label={
                  <>
                    Seal Number&nbsp;
                    <Tooltip content="Provides the seal number used to secure the container." positioning={{ placement: 'right-end' }}>
                      <IconButton
                        width="14px"
                        height="14px"
                        padding="0"
                        minW="auto"
                        borderRadius="50%"
                        color="#878787"
                        variant="unstyled"
                      >
                        <IoInformationCircleOutline size="14px" />
                      </IconButton>
                    </Tooltip>
                  </>
                }
              >
                <Input
                  backgroundColor="white"
                  name="seal-number"
                  value={form?.billOfLading?.sealNumber || ''}
                  disabled={!editMode}
                  className="form-control"
                  onChange={e => onObjectValueChange('billOfLading', 'sealNumber', e.target.value)}
                />
              </CustomFormControl>
              <CustomFormControl
                width="100%"
                label={
                  <>
                    Export Declaration Number&nbsp;
                    <Tooltip
                      content="The Export Declaration Number is a required identifier for customs clearance"
                      positioning={{ placement: 'right-end' }}
                    >
                      <IconButton
                        width="14px"
                        height="14px"
                        padding="0"
                        minW="auto"
                        borderRadius="50%"
                        color="#878787"
                        variant="unstyled"
                      >
                        <IoInformationCircleOutline size="14px" />
                      </IconButton>
                    </Tooltip>
                  </>
                }
              >
                <Input
                  backgroundColor="white"
                  name="export-declaration-number"
                  value={form?.billOfLading?.exportDeclarationNumber || ''}
                  disabled={!editMode}
                  className="form-control"
                  onChange={e => onObjectValueChange('billOfLading', 'exportDeclarationNumber', e.target.value)}
                />
              </CustomFormControl>
              <HStack gap="11px" width="fit-content">
                <CustomFormControl
                  width="100%"
                  label={
                    <>
                      Gross Shipped Weight&nbsp;
                      <Tooltip
                        content="Records the total weight of the shipment in kilograms for accurate documentation"
                        positioning={{ placement: 'right-end' }}
                      >
                        <IconButton
                          width="14px"
                          height="14px"
                          padding="0"
                          minW="auto"
                          borderRadius="50%"
                          color="#878787"
                          variant="unstyled"
                        >
                          <IoInformationCircleOutline size="14px" />
                        </IconButton>
                      </Tooltip>
                    </>
                  }
                >
                  <Input
                    backgroundColor="white"
                    name="gross-shipped-weight"
                    type="text"
                    value={form?.billOfLading?.grossShippedWeight || ''}
                    disabled={!editMode}
                    className="form-control"
                    onChange={e => handleGrossShippedWeightChange('billOfLading', 'grossShippedWeight', e.target.value)}
                    onBlur={e => handleGrossShippedWeightBlur('billOfLading', 'grossShippedWeight', e.target.value)}
                  />
                </CustomFormControl>
                <Text as="p" fontWeight="medium" marginTop="24px !important">
                  kgs
                </Text>
              </HStack>
            </Grid>
          </VStack>
        )}
      </VStack>
      <VStack gap="11px" align="stretch">
        <HStack justifyContent="space-between" color="accent.one.default" width="180px">
          <Text as="p" fontWeight="medium" color="inherit">
            Shipment/Port Marks
          </Text>
          <IconButton
            variant="unstyled"
            minWidth="18px"
            height="fit-content"
            padding="0px"
            fontSize="12px"
            onClick={() => setState({ ...state, shipmentPortIsExpanded: !state.shipmentPortIsExpanded })}
          >
            {state.shipmentPortIsExpanded ? <FaChevronDown /> : <FaChevronRight />}
          </IconButton>
        </HStack>
        {state.shipmentPortIsExpanded &&
          (form?.billOfLading?.shipmentPortMarks.length ? (
            form?.billOfLading?.shipmentPortMarks.map((spMark, index) => {
              return (
                <ShipmentPortMark
                  key={`shipment-port-mark-${index}`}
                  item={spMark}
                  form={form}
                  editMode={editMode}
                  index={index}
                  handleChangeObjectList={handleChangeObjectList}
                  handleRemoveObjectListItem={handleRemoveObjectListItem}
                  onDeleteMark={deleteMark}
                  onAddMark={addMark}
                  count={form?.billOfLading?.shipmentPortMarks.length}
                />
              );
            })
          ) : (
            <Text as="p">No shipment/Port marks</Text>
          ))}
      </VStack>
      <Box>
        <Button
          colorScheme="actionPrimary"
          borderRadius={2}
          onClick={addMark}
          disabled={!editMode}
          hidden={!editMode}
        >
          Add Shipment/Port Mark
        </Button>
      </Box>
    </VStack>
  );
};

BillOfLading.propTypes = propTypes;
export default BillOfLading;
