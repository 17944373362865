import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, VStack, HStack, IconButton, Grid } from '@chakra-ui/react';
import { isEqual } from 'lodash';
import { IoInformationCircleOutline } from 'react-icons/io5';
import SelectField from '../../../../basic/SelectField';
import FECSelection from '../../FECSelection';
import CustomFormControl from '../../../../core/CustomFormControl';
import { withInternationalOrderFormContext } from '../../InternationalOrderForm/InternationalOrderFormContext';
import POFormFieldLabel from '../POFormFieldLabel';
import { getOr } from '../../../../../helpers';
import { Tooltip } from '@/components/ui/tooltip';
import { Switch } from '@/components/ui/switch';

const propTypes = {
  currencies: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
    })
  ),
  incoTerms: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
    })
  ),
  fecs: PropTypes.arrayOf(PropTypes.object),

  unitsOfMeasure: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  handleChangeValue: PropTypes.func,
  onBooleanValueChange: PropTypes.func,
  editMode: PropTypes.bool,
  state: {
    form: PropTypes.shape({
      buyCurrency: PropTypes.string,
      buyIncoterms: PropTypes.string,
      buyUnitOfMeasureId: PropTypes.n,
      fec: PropTypes.instanceOf(Object),
      halal: PropTypes.bool,
      mtc: PropTypes.bool,
      sellCurrency: PropTypes.string,
      sellIncoterms: PropTypes.string,
      sellUnitOfMeasureId: PropTypes.number,
    }),
    requiredFields: PropTypes.shape({
      form: PropTypes.arrayOf(PropTypes.string),
    }),
  },
  validationErrors: PropTypes.shape({
    requiredFields: PropTypes.instanceOf(Object),
    dates: PropTypes.bool,
  }),
  showToggles: PropTypes.bool,
};

const InternationalOrderDetails = props => {
  const { unitsOfMeasure, incoTerms, fecs, currencies, onBooleanValueChange, editMode, validationErrors, showToggles } =
    props;
  const onChangeValue = props.handleChangeValue ? props.handleChangeValue : props.onChangeValue;
  const { form } = props.state ? props.state : props;

  const requiredFields = getOr(props, 'requiredFields', getOr(validationErrors, 'requiredFields', []));

  const requiredFieldsForm = getOr(requiredFields, 'form', []);

  const uomOptions = unitsOfMeasure.map(el => {
    return { value: el.id, label: el.name };
  });

  const incoTermsOptions = incoTerms.map(el => {
    return { value: el.value, label: el.value };
  });

  const currenciesOptions = currencies.map(el => {
    return { value: el.value, label: el.value };
  });

  return (
    <VStack gap="39px" align="stretch" mt="2">
      <Grid gap="29px" templateColumns="repeat(4, 1fr)">
        <CustomFormControl label="INCO Terms" labelProps={{ style: { color: '#fd5c38' } }}>
          <HStack width="100%">
            <VStack align="stretch" width="100%">
              <POFormFieldLabel
                label="Buy"
                htmlFor="select-buyIncoTerms"
                fieldName="buyIncoterms"
                tooltip="Specifies international commercial terms for buying (CIF, DAP, DCT, FAS, FOB)."
              />
              <SelectField
                disabled={!editMode}
                name="select-buyIncoTerms"
                defaultValue={form.buyIncoterms}
                options={incoTermsOptions?.sort((a, b) => (a.label > b.label ? 1 : -1))}
                className={requiredFieldsForm.includes('buyIncoterms') ? 'error' : ''}
                onChange={e => onChangeValue('buyIncoterms', e)}
              />
            </VStack>
            <VStack align="stretch" width="100%">
              <POFormFieldLabel
                label="Sell"
                htmlFor="select-sellIncoTerms"
                fieldName="sellIncoterms"
                tooltip="Specifies international commercial terms for selling (CIF, DAP, DCT, FAS, FOB)"
              />
              <SelectField
                disabled={!editMode}
                name="select-sellIncoTerms"
                defaultValue={form.sellIncoterms}
                options={incoTermsOptions?.sort((a, b) => (a.label > b.label ? 1 : -1))}
                className={requiredFieldsForm.includes('sellIncoterms') ? 'error' : ''}
                onChange={e => onChangeValue('sellIncoterms', e)}
              />
            </VStack>
          </HStack>
        </CustomFormControl>
        <CustomFormControl label="UOM" labelProps={{ style: { color: '#fd5c38' } }}>
          <HStack width="100%">
            <VStack align="stretch" width="100%">
              <POFormFieldLabel
                label="Buy"
                htmlFor="select-buyUnitOfMeasureId"
                fieldName="buyUnitOfMeasureId"
                tooltip="Specifies the unit of measure for buying (BIN, CTN, KGS, LBS)."
              />
              <SelectField
                disabled={!editMode}
                name="select-buyUnitOfMeasureId"
                defaultValue={form.buyUnitOfMeasureId}
                options={uomOptions?.sort((a, b) => (a.label > b.label ? 1 : -1))}
                className={requiredFieldsForm.includes('buyUnitOfMeasureId') ? 'error' : ''}
                isClearable={false}
                onChange={e => onChangeValue('buyUnitOfMeasureId', e)}
              />
            </VStack>
            <VStack align="stretch" width="100%">
              <POFormFieldLabel
                label="Sell"
                htmlFor="select-sellUnitOfMeasureId"
                fieldName="sellUnitOfMeasureId"
                tooltip="Specifies the unit of measure for selling (BIN, CTN, KGS, LBS)."
              />
              <SelectField
                disabled={!editMode}
                name="select-sellUnitOfMeasureId"
                defaultValue={form.sellUnitOfMeasureId}
                options={uomOptions?.sort((a, b) => (a.label > b.label ? 1 : -1))}
                className={requiredFieldsForm.includes('sellUnitOfMeasureId') ? 'error' : ''}
                isClearable={false}
                onChange={e => onChangeValue('sellUnitOfMeasureId', e)}
              />
            </VStack>
          </HStack>
        </CustomFormControl>
        <CustomFormControl label="Currency" labelProps={{ style: { color: '#fd5c38' } }}>
          <HStack width="100%">
            <VStack align="stretch" width="100%">
              <POFormFieldLabel
                label="Buy"
                htmlFor="select-buyCurrency"
                fieldName="buyCurrency"
                tooltip="Specifies the currency for buying (AUD, NZD, USD)."
              />
              <SelectField
                disabled={!editMode}
                name="select-buyCurrency"
                defaultValue={form.buyCurrency}
                options={currenciesOptions?.sort((a, b) => (a.label > b.label ? 1 : -1))}
                className={requiredFieldsForm.includes('buyCurrency') ? 'error' : ''}
                onChange={e => onChangeValue('buyCurrency', e)}
                isClearable={false}
              />
            </VStack>
            <VStack align="stretch" width="100%">
              <POFormFieldLabel
                label="Sell"
                htmlFor="select-sellCurrency"
                fieldName="sellCurrency"
                tooltip="Specifies the currency for selling (AUD, NZD, USD)."
              />
              <SelectField
                disabled={!editMode}
                name="select-sellCurrency"
                defaultValue={form.sellCurrency}
                options={currenciesOptions?.sort((a, b) => (a.label > b.label ? 1 : -1))}
                className={requiredFieldsForm.includes('sellCurrency') ? 'error' : ''}
                onChange={e => onChangeValue('sellCurrency', e)}
                isClearable={false}
              />
            </VStack>
          </HStack>
        </CustomFormControl>
        {form.buyCurrency && form.sellCurrency && form.buyCurrency !== form.sellCurrency && (
          <CustomFormControl label="FEC">
            <Box>
              <FECSelection
                disabled={!editMode}
                value={form?.fec || null}
                options={fecs}
                onChange={e => onChangeValue('fec', e)}
                requiredFields={requiredFields}
                filter={option => option.buyCurrency === form.buyCurrency && option.sellCurrency === form.sellCurrency}
              />
            </Box>
          </CustomFormControl>
        )}
      </Grid>
      {showToggles && (
        <Flex gap="29px">
          <CustomFormControl
            label={
              <>
                Halal Req&nbsp;
                <Tooltip content="Displays whether Halal certification is required (Yes or No)." positioning={{ placement: 'right-end' }}>
                  <IconButton
                    width="14px"
                    height="14px"
                    padding="0"
                    minW="auto"
                    borderRadius="50%"
                    color="#878787"
                    variant="unstyled"
                  >
                    <IoInformationCircleOutline size="14px" />
                  </IconButton>
                </Tooltip>
                
              </>
            }
          >
            <HStack>
            No
              <Switch
                size="lg"
                id="halal-req"
                name="halal"
                colorScheme="actionPrimary"
                disabled={!editMode}
                checked={form.halal}
                onChange={() => onBooleanValueChange('halal')}
              >
                Yes
              </Switch>
            </HStack>
          </CustomFormControl>
          <CustomFormControl
            label={
              <>
                MTC&nbsp;
                <Tooltip
                  content="Displays whether MTC (Meat Transfer Certificate) is required (Yes or No)."
                  positioning={{ placement: 'right-end' }}
                >
                  <IconButton
                    width="14px"
                    height="14px"
                    padding="0"
                    minW="auto"
                    borderRadius="50%"
                    color="#878787"
                    variant="unstyled"
                  >
                    <IoInformationCircleOutline size="14px" />
                  </IconButton>
                </Tooltip>
              </>
            }
          >
            <HStack>
              No
              <Switch
                size="lg"
                id="mtc-req"
                name="mtc"
                colorScheme="actionPrimary"
                disabled={!editMode}
                checked={form.mtc}
                value={form.buyIncoterms}
                onChange={() => onBooleanValueChange('mtc')}
              >
                {form.mtc ? 'No' : 'Yes'}
              </Switch>
            </HStack>
          </CustomFormControl>
        </Flex>
      )}
    </VStack>
  );
};

InternationalOrderDetails.propTypes = propTypes;

export default withInternationalOrderFormContext(React.memo(InternationalOrderDetails, isEqual));
