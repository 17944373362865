import React from 'react';
import Datetime from '@/components/core/DatePicker';

const POMultipleFilters = props => {
  const startDateProps = {
    placeholder: 'Start Date',
    className: 'form-control m-input',
  };
  const endDateProps = {
    placeholder: 'End Date',
    className: 'form-control m-input',
  };

  return (
    <div className="row" style={{ marginRight: '10px' }}>
      <div className="col-sm-3">
        <label>
          <strong>Date Created Start/End</strong>
        </label>
        <div className="row">
          <div className="col-sm-6">
            <Datetime
              id="ordered_start_date"
              inputProps={startDateProps}
              closeOnSelect
              value={props.filters.start_date}
              onChange={e => {
                if (e._isAMomentObject) {
                  props.onFilterChange('start_date', e.format('YYYY-MM-DD'));
                } else {
                  props.onFilterChange('start_date', e);
                }
              }}
              timeFormat={false}
              dateFormat="YYYY-MM-DD"
            />
          </div>
          <div className="col-sm-6">
            <Datetime
              id="ordered_end_date"
              inputProps={endDateProps}
              closeOnSelect
              value={props.filters.end_date}
              onChange={e => {
                if (e._isAMomentObject) {
                  props.onFilterChange('end_date', e.format('YYYY-MM-DD'));
                } else {
                  props.onFilterChange('end_date', e);
                }
              }}
              timeFormat={false}
              dateFormat="YYYY-MM-DD"
            />
          </div>
        </div>
      </div>
      <div className="col-sm-3">
        <label>
          <strong>Date Delivery Start/End</strong>
        </label>
        <div className="row">
          <div className="col-sm-6">
            <Datetime
              id="delivery_start_date"
              inputProps={startDateProps}
              closeOnSelect
              value={props.filters.delivery_start_date}
              onChange={e => {
                if (e._isAMomentObject) {
                  props.onFilterChange('delivery_start_date', e.format('YYYY-MM-DD'));
                } else {
                  props.onFilterChange('delivery_start_date', e);
                }
              }}
              timeFormat={false}
              dateFormat="YYYY-MM-DD"
            />
          </div>
          <div className="col-sm-6">
            <Datetime
              id="delivery_end_date"
              inputProps={endDateProps}
              closeOnSelect
              value={props.filters.delivery_end_date}
              onChange={e => {
                if (e._isAMomentObject) {
                  props.onFilterChange('delivery_end_date', e.format('YYYY-MM-DD'));
                } else {
                  props.onFilterChange('delivery_end_date', e);
                }
              }}
              timeFormat={false}
              dateFormat="YYYY-MM-DD"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default POMultipleFilters;
