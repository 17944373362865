import React, { Component } from 'react';
import Datetime from '@/components/core/DatePicker';
// Local Deps
import { patchPackerOffer } from '../../../../actions/actions_packer_offer';
import EditPackerOfferLines from '../../../../containers/packers/EditPackerOfferLines';
import { validatePackerOfferForm } from '../../../../functions';
import ValidationErrors from '../../validators/ValidationErrors';
import SelectField from '../../../basic/SelectField';

class EditPackerOfferForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      validationErrors: [],
      editMode: false,
      dateWarnings: [],
      form: {},
    };
    this.onValueChange = this.onValueChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.item) {
      const copy = JSON.parse(JSON.stringify(nextProps.item));
      copy.lines.forEach(line => (line.active = false));
      copy.packer_id = copy.packer_id !== null ? `${copy.packer_id}_pg` : `${copy.packer_plant_id}_p`;
      this.setState({ form: copy });
    }
  }

  componentDidMount() {
    window.addEventListener('keypress', this.removeAllWarnings, false);
  }

  componentWillUnmount() {
    window.removeEventListener('keypress', this.removeAllWarnings);
  }

  saveOffer = () => {
    const copy = Object.assign({}, this.state.form);
    const form = {
      end_date: '',
      start_date: '',
      packer_id: '',
      delivery_start_date: '',
      delivery_end_date: '',
      price_source: '',
      packer_plant_id: null,
    };

    Object.keys(form).forEach(key => (form[key] = copy[key]));

    const validationErrors = validatePackerOfferForm(this.state.form);
    this.setState({ validationErrors });

    if (validationErrors.length === 0) {
      if (form.packer_plant_id) {
        form.packer_id = null;
        form.packer_plant_id = form.packer_plant_id.split('_')[0];
      } else {
        form.packer_plant_id = null;
        form.packer_id = parseInt(form.packer_id.split('_')[0]);
      }
      this.props.dispatch(patchPackerOffer(copy.id, form, this.props.token));
      this.closeModal();
    }
  };

  removeAllWarnings = () => {
    this.setState({ dateWarnings: [] });
  };

  enableEdit = () => {
    this.setState({ editMode: true });
  };

  onValueChange = (fieldName, value) => {
    const newFormState = Object.assign({}, this.state.form);
    newFormState[fieldName] = value;
    this.setState({ form: newFormState });
  };

  onOfferFromChange(e) {
    const EST_PREFIX = 'Est. Group - ';
    if (e === null) {
      return;
    }
    const newFormState = Object.assign({}, this.state.form);
    newFormState.packer_id = e.value;

    if (!e.label.startsWith(EST_PREFIX)) {
      newFormState.packer_plant_id = e.value;
    } else {
      newFormState.packer_plant_id = null;
    }

    this.setState({ form: newFormState });
  }

  updateValidationErrors = errors => {
    this.setState({ validationErrors: errors });
  };

  removeWarning = index => {
    const stateCopy = Object.assign({}, this.state);
    stateCopy.dateWarnings.splice(stateCopy.dateWarnings.indexOf(index));
    this.setState(stateCopy);
  };

  closeModal = () => {
    this.setState({
      editMode: false,
      validationErrors: [],
      dateWarnings: [],
      form: {},
    });
    this.props.close(false, 'editOpened', false);
  };

  render() {
    const { form, editMode, dateWarnings, validationErrors } = this.state;

    const offerDateProps = {
      placeholder: 'Offer Date',
      className: 'form-control m-input',
      disabled: !editMode,
    };

    const deliveryStartDateInputProps = {
      placeholder: 'Delivery Start Date',
      className: 'form-control m-input',
      disabled: !editMode,
    };

    const deliveryEndDateInputProps = {
      placeholder: 'Delivery End Date',
      className: 'form-control m-input',
      disabled: !editMode,
    };

    const offerFromOptions = [];

    this.props.packers.forEach(function (element) {
      offerFromOptions.push({
        value: `${element.id}_pg`,
        label: `Est. Group - ${element.name}`,
      });
    });

    this.props.packer_plants.forEach(function (element) {
      offerFromOptions.push({
        value: `${element.uid}_p`,
        label: element.name,
        packer_plant: true,
      });
    });

    const offerSourceOptions = [];
    this.props.offer_source.forEach(function (element) {
      const capitalized = element.value.charAt(0).toUpperCase() + element.value.slice(1);
      offerSourceOptions.push({ value: element.value, label: capitalized });
    });

    return (
      <div>
        {this.props.opened ? (
          <div style={{ display: 'block' }}>
            <div
              className="modal fade show"
              id="m_modal_4"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              style={{ overflowY: 'scroll', display: 'block' }}
            >
              <div className="modal-dialog modal-full" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Edit Packer Offer
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        this.closeModal();
                      }}
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div style={{ paddingBottom: '30px' }}>
                      <div className="row">
                        <div className="col-md-12">
                          {this.props.item.status !== 'cancelled' && !editMode ? (
                            <button
                              className="col-sm-2 btn btn-success pull-right"
                              style={{ height: 'fit-content' }}
                              onClick={this.enableEdit}
                            >
                              Edit Offer
                            </button>
                          ) : (
                            false
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-2">
                          <label htmlFor="offer_start_date">Offer Date</label>
                          <Datetime
                            id="offer_start_date"
                            inputProps={offerDateProps}
                            closeOnSelect
                            value={form.end_date}
                            onChange={e =>
                              this.onValueChange('start_date', e._isAMomentObject ? e.format('YYYY-MM-DD') : e)
                            }
                            disabled={!editMode}
                            timeFormat={false}
                            dateFormat="YYYY-MM-DD"
                          />
                        </div>
                        <div className="col-sm-3">
                          <SelectField
                            label="Offer From"
                            name="select-packer"
                            defaultValue={form.packer_id}
                            options={offerFromOptions}
                            placeholder="Offer From"
                            onChange={e => {
                              this.onOfferFromChange(e || null);
                            }}
                            disabled={!editMode}
                          />
                        </div>
                        <div className="col-sm-2" />
                      </div>
                      <div className="row" style={{ paddingTop: '20px' }}>
                        <div className="col-sm-2">
                          <label htmlFor="delivery_start_date">Delivery Start Date</label>
                          <Datetime
                            id="delivery_start_date"
                            inputProps={deliveryStartDateInputProps}
                            closeOnSelect
                            value={form.delivery_start_date}
                            onChange={e =>
                              this.onValueChange(
                                'delivery_start_date',
                                e._isAMomentObject ? e.format('YYYY-MM-DD') : e
                              )
                            }
                            timeFormat={false}
                            dateFormat="YYYY-MM-DD"
                          />
                        </div>
                        <div className="col-sm-2">
                          <label htmlFor="delivery_end_date">Delivery End Date</label>
                          <Datetime
                            id="delivery_end_date"
                            inputProps={deliveryEndDateInputProps}
                            closeOnSelect
                            value={form.delivery_end_date}
                            onChange={e =>
                              this.onValueChange(
                                'delivery_end_date',
                                e._isAMomentObject ? e.format('YYYY-MM-DD') : e
                              )
                            }
                            timeFormat={false}
                            dateFormat="YYYY-MM-DD"
                          />
                        </div>
                        <div className="col-sm-3" />
                        <div className="col-sm-2">
                          <SelectField
                            label="Offer Source"
                            disabled={!editMode}
                            name="select-input-product"
                            defaultValue={form.price_source}
                            options={offerSourceOptions}
                            onChange={e => {
                              this.onValueChange('price_source', e ? e.value : null);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <EditPackerOfferLines
                      dateWarnings={dateWarnings}
                      updateValidationErrors={this.updateValidationErrors}
                      packer_offer_id={form.id}
                      close={this.closeModal}
                      form={form}
                    />
                    <ValidationErrors errors={validationErrors} />
                    <div className="modal-footer" style={{ justifyContent: 'baseline' }}>
                      <div style={{ width: '100%' }}>
                        <button
                          type="button"
                          className="btn btn-info pull-right"
                          data-dismiss="modal"
                          onClick={this.saveOffer}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-backdrop fade show" />
          </div>
        ) : (
          false
        )}
      </div>
    );
  }
}

export default EditPackerOfferForm;
