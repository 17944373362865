import { Text, Table, Box } from '@chakra-ui/react';
import React from 'react';
import PropTypes from 'prop-types';
import { formatMonetaryValue } from '../../../../functions';

const thTdProps = {
  padding: '10px',
  paddingLeft: '0px',
};
const SummaryOfCostsTableTd = ({ children, ...rest }) => {
  return (
    <Table.Cell {...thTdProps} {...rest}>
      {children}
    </Table.Cell>
  );
};

SummaryOfCostsTableTd.propTypes = {
  children: PropTypes.node.isRequired,
};

const SummaryOfCostsTableTh = ({ children, ...rest }) => {
  return (
    <Table.ColumnHeader {...thTdProps} {...rest}>
      {children}
    </Table.ColumnHeader>
  );
};

SummaryOfCostsTableTh.propTypes = {
  children: PropTypes.node.isRequired,
};

const SummaryOfCosts = ({ order }) => {
  const lines = Object.values(order.lines);

  return (
    <Box>
      <Table.Root variant="unstyled" width="fit-content">
        <Table.Header>
          <Table.Row>
            <SummaryOfCostsTableTh>
              <Text as="p" fontWeight="bold" color="accent.one.default" textTransform="capitalize" fontSize="14px">
                Summary of Costs:
              </Text>
            </SummaryOfCostsTableTh>
            {lines.map((line, index) => (
              <SummaryOfCostsTableTh key={`summary_${index}`} color="inherit">
                <Text as="p" fontWeight="bold" textTransform="capitalize" fontSize="14px">
                  Line {index + 1}
                </Text>
              </SummaryOfCostsTableTh>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <SummaryOfCostsTableTd paddingLeft="0px" paddingRight="123px">
              <Text as="p" fontWeight="bold">
                Total Cost of Ingredients
              </Text>
            </SummaryOfCostsTableTd>
            {lines.map((line, index) => (
              <SummaryOfCostsTableTd key={`summary_${index}`} paddingRight="48px">
                <Text as="p">
                  {formatMonetaryValue(order.buyCurrency, line.buyPrice ? parseFloat(line.buyPrice) : 0, {
                    notation: 'standard',
                  })}
                </Text>
              </SummaryOfCostsTableTd>
            ))}
          </Table.Row>
          <Table.Row>
            <SummaryOfCostsTableTd paddingLeft="0px" paddingRight="123px">
              <Text as="p" fontWeight="bold">
                Total Cost per Unit
              </Text>
            </SummaryOfCostsTableTd>
            {lines.map((line, index) => (
              <SummaryOfCostsTableTd key={`summary_${index}`}>
                <Text as="p">
                  {formatMonetaryValue(order.buyCurrency, line.buyPricePerUnit ? parseFloat(line.buyPricePerUnit) : 0, {
                    notation: 'standard',
                    maximumFractionDigits: 4,
                    minimumFractionDigits: 4,
                  })}
                </Text>
              </SummaryOfCostsTableTd>
            ))}
          </Table.Row>
        </Table.Body>
      </Table.Root>
    </Box>
  );
};

SummaryOfCosts.propTypes = {
  order: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default SummaryOfCosts;
