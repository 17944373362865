/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// Local
import Constants from '../../../../Constants';
import './ColdStoreDetails.scss';
import SelectField from '../../../basic/SelectField';
import ColdStoreLotNumbers from './ColdStoreLotNumbers';
import { Badge, Box, HStack, IconButton, Input, Text, VStack, Flex, Grid } from '@chakra-ui/react';
import CustomFormControl from '../../../core/CustomFormControl';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa';
import { TimePicker } from 'antd';
import moment from 'moment';
import { sortInAlphabeticalOrder } from '../../../../functions';
import DateTimeWithTrappedFocus from '../../../core/DateTimeWithTrappedFocus';
import { Tooltip } from '@/components/ui/tooltip';
import { IoInformationCircleOutline } from 'react-icons/io5';
import { Checkbox } from '@/components/ui/checkbox';

const propTypes = {
  disabled: PropTypes.bool,
  coldStoreDetails: PropTypes.shape({
    coldStore: PropTypes.shape({
      address: PropTypes.shape({
        city: PropTypes.string,
        country: PropTypes.string,
        line1: PropTypes.string,
        line2: PropTypes.string,
        postCode: PropTypes.string,
        state: PropTypes.string,
      }),
      addressId: PropTypes.number,
      companyName: PropTypes.string,
      id: PropTypes.number,
      initialFreeTime: PropTypes.number,
      initialPrice: PropTypes.number,
      initialPriceCurrency: PropTypes.string,
      lat: PropTypes.number,
      locationName: PropTypes.string,
      lon: PropTypes.number,
      notificationEmail: PropTypes.string,
      rollWindow: PropTypes.number,
      rollWindowPrice: PropTypes.number,
      rollWindowPriceCurrency: PropTypes.string,
      timezone: PropTypes.string,
    }),
    coldStoreId: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]), // TODO -- resolve
    containerBackTemp: PropTypes.string,
    containerFrontTemp: PropTypes.string,
    containerMiddleTemp: PropTypes.string,
    dateOfEntry: PropTypes.string,
    id: PropTypes.number,
    inspectionDate: PropTypes.string,
    receiptDate: PropTypes.string,
    lotNumbers: PropTypes.arrayOf(PropTypes.string),
    overRedLine: PropTypes.bool,
    purchaseOrderId: PropTypes.number,
    temperatureMeasurementUnitId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    customedClearedDate: PropTypes.Datetime,
  }).isRequired,
  coldStores: PropTypes.arrayOf(
    PropTypes.shape({
      address: PropTypes.shape({
        city: PropTypes.string,
        country: PropTypes.string,
        line1: PropTypes.string,
        line2: PropTypes.string,
        postCode: PropTypes.string,
        state: PropTypes.string,
      }),
      addressId: PropTypes.number,
      companyName: PropTypes.string,
      id: PropTypes.number,
      initialFreeTime: PropTypes.number,
      initialPrice: PropTypes.number,
      initialPriceCurrency: PropTypes.string,
      lat: PropTypes.number,
      locationName: PropTypes.string,
      lon: PropTypes.number,
      notificationEmail: PropTypes.string,
      rollWindow: PropTypes.number,
      rollWindowPrice: PropTypes.number,
      rollWindowPriceCurrency: PropTypes.string,
      timezone: PropTypes.string,
    })
  ),
  defaultColdstoreID: PropTypes.number,
  onObjectValueChange: PropTypes.func,
  handleAtColdstore: PropTypes.func,
  setColdstoreStatus: PropTypes.bool,
  form: PropTypes.instanceOf(Object),
  transporterOptions: PropTypes.arrayOf(PropTypes.object),
  handleDateChange: PropTypes.func,
  validationErrors: PropTypes.arrayOf(PropTypes.object),
  isUSADomestic: PropTypes.bool,
  isFrozenProduct: PropTypes.bool,
};

const ColdStoreDetails = ({
  disabled,
  coldStoreDetails,
  coldStores,
  defaultColdstoreID,
  onObjectValueChange,
  handleAtColdstore,
  setColdstoreStatus,
  form,
  transporterOptions,
  handleDateChange,
  validationErrors,
  isUSADomestic,
  isFrozenProduct,
}) => {
  const [state, setState] = useState({
    temperatureIsExpanded: true,
    coldStoreReleasedIsExpanded: true,
    refuseDeliveryTimePicker: false,
  });

  useEffect(() => {
    setState({ ...state, refuseDeliveryTimePicker: !form.scheduledDeliveryTime });
  }, [form.scheduledDeliveryTime]);

  const coldStoreOptions = [];
  const handleInspectionDateChange = e => {
    // eslint-disable-next-line no-underscore-dangle
    const inspectionDate = e._isAMomentObject ? e.format('YYYY-MM-DD') : e;
    onObjectValueChange('coldstoreDetails', 'inspectionDate', inspectionDate);
    handleAtColdstore(Boolean(coldStoreDetails.inspectionDate || inspectionDate));
  };

  const handleReceiptDateChange = e => {
    // eslint-disable-next-line no-underscore-dangle
    const receiptDate = e._isAMomentObject ? e.format('YYYY-MM-DD') : e;
    onObjectValueChange('coldstoreDetails', 'receiptDate', receiptDate);
    handleAtColdstore(Boolean(coldStoreDetails.receiptDate || receiptDate));
  };

  coldStores.forEach(el => {
    coldStoreOptions.push({ label: el.locationName, value: el.id });
  });

  coldStoreOptions.sort((a, b) => {
    const packerA = a.label.toLowerCase();

    const packerB = b.label.toLowerCase();
    return sortInAlphabeticalOrder(packerA, packerB);
  });

  let selectedColdStoreID = null;

  if (coldStoreDetails?.coldStoreId) {
    selectedColdStoreID = coldStoreDetails.coldStoreId;
  } else {
    selectedColdStoreID = defaultColdstoreID;
  }

  const InspectionDateOptions = {
    className: 'form-control m-input',
    disabled: disabled || selectedColdStoreID === null,
  };

  const CustomsDateOptions = {
    className: 'form-control m-input',
    disabled: disabled || selectedColdStoreID === null,
  };

  const temperatureMeasurementUnit = coldStoreDetails?.temperatureMeasurementUnitId
    ? Constants.TEMPERATURE_MEASUREMENT_UNITS.find(unit => unit.value === coldStoreDetails.temperatureMeasurementUnitId)
    : Constants.TEMPERATURE_MEASUREMENT_UNITS.find(unit => unit.label === Constants.FAHRENHEIT);

  const areColdstoreReleaseFieldsDisabled = true;

  return (
    <VStack align="stretch" gap="22px">
      <Grid templateColumns="repeat(4, 1fr)" gap="20px">
        {/* <!-- COLDSTORE LOCATION --> */}

        <SelectField
          width="100%"
          label={
            <>
              Coldstore Location&nbsp;
              <Tooltip
                content="Specifies the location within the cold storage facility where the goods are stored"
                positioning={{ placement: 'right-end' }}
              >
                <IconButton
                  width="14px"
                  height="14px"
                  padding="0"
                  minW="auto"
                  borderRadius="50%"
                  color="#878787"
                  variant="unstyled"
                >
                  <IoInformationCircleOutline size="14px" />
                </IconButton>
              </Tooltip>
            </>
          }
          isClearable
          disabled={disabled}
          name="coldstore-location"
          defaultValue={selectedColdStoreID}
          options={coldStoreOptions}
          onChange={e => onObjectValueChange('coldstoreDetails', 'coldStoreId', e ? e.value : '')}
        />

        {/* <!-- INSPECTION DATE & TIME --> */}
        {!isUSADomestic ? (
          <CustomFormControl
            width="100%"
            label={
              <>
                Customs Cleared Date&nbsp;
                <Tooltip
                  content="Records the date when the shipment clears customs for tracking customs clearance processes."
                  positioning={{ placement: 'right-end' }}
                >
                  <IconButton
                    width="14px"
                    height="14px"
                    padding="0"
                    minW="auto"
                    borderRadius="50%"
                    color="#878787"
                    variant="unstyled"
                  >
                    <IoInformationCircleOutline size="14px" />
                  </IconButton>
                </Tooltip>
              </>
            }
          >
            <DateTimeWithTrappedFocus
              id="customs-cleared-date"
              inputProps={CustomsDateOptions}
              closeOnSelect
              disabled={disabled}
              value={coldStoreDetails?.customedClearedDate}
              onChange={e =>
                onObjectValueChange(
                  'coldstoreDetails',
                  'customedClearedDate',
                  // eslint-disable-next-line no-underscore-dangle
                  e._isAMomentObject ? e.format('YYYY-MM-DD') : e
                )
              }
              timeFormat={false}
              dateFormat="YYYY-MM-DD"
            />
          </CustomFormControl>
        ) : (
          ''
        )}
        <CustomFormControl width="100%" position="relative">
          <VStack gap="2px" align="stretch">
            {setColdstoreStatus || coldStoreDetails?.inspectionDate ? (
              <Badge
                color="#FFFFFF"
                backgroundColor="#FFAB00"
                textTransform="uppercase"
                fontWeight="bold"
                fontSize="12px"
                paddingX="4px"
                paddingY="1px"
                borderRadius="2px"
                margin="0px"
                width="fit-content"
                position="absolute"
                top="-20px"
              >
                ADDED TO COLDSTORE
              </Badge>
            ) : null}
            <label htmlFor="inspection-date">
              {isUSADomestic && !isFrozenProduct ? (
                'Coldstore Receipt Date'
              ) : (
                <>
                  Inspection Date&nbsp;
                  <Tooltip
                    content="Records the date when the inspection of the goods occurred for quality control"
                    positioning={{ placement: 'right-end' }}
                  >
                    <IconButton
                      width="14px"
                      height="14px"
                      padding="0"
                      minW="auto"
                      borderRadius="50%"
                      color="#878787"
                      variant="unstyled"
                    >
                      <IoInformationCircleOutline size="14px" />
                    </IconButton>
                  </Tooltip>
                </>
              )}
            </label>
          </VStack>
          <DateTimeWithTrappedFocus
            id={isUSADomestic ? 'coldstore-receipt-date' : 'inspection-date'}
            inputProps={InspectionDateOptions}
            closeOnSelect
            value={isUSADomestic && !isFrozenProduct ? coldStoreDetails?.receiptDate : coldStoreDetails?.inspectionDate}
            onChange={e =>
              isUSADomestic && !isFrozenProduct ? handleReceiptDateChange(e) : handleInspectionDateChange(e)
            }
            timeFormat={false}
            dateFormat="YYYY-MM-DD"
          />
        </CustomFormControl>
        {/* <!-- LOT NUMBERS --> */}
        <Box width="100%">
          <label htmlFor="lot-number">
            Lot Numbers&nbsp;
            <Tooltip
              content="Records or displays the lot numbers associated with the shipment for tracking specific batches"
              positioning={{ placement: 'right-end' }}
            >
              <IconButton
                width="14px"
                height="14px"
                padding="0"
                minW="auto"
                borderRadius="50%"
                color="#878787"
                variant="unstyled"
              >
                <IoInformationCircleOutline size="14px" />
              </IconButton>
            </Tooltip>
          </label>
          <ColdStoreLotNumbers
            lotNumbers={coldStoreDetails?.lotNumbers}
            disabled={disabled}
            onObjectValueChange={onObjectValueChange}
          />
        </Box>
        {/* <!-- AT COLDSTORE CHECKBOX --> 
        <div className="col-sm-2" style={{ display: 'none' }}>
          At coldstore &nbsp; &nbsp;
          <input
            id="at-coldstore"
            type="checkbox"
            disabled={disabled}
            style={checkBoxStyle}
            checked={setColdstoreStatus}
            value={setColdstoreStatus}
            onChange={e => handleAtColdstore(e.target.checked)}
          />
        </div> */}
      </Grid>
      {/* <!-- CONTAINER TEMPERATURES --> */}
      {!isUSADomestic ? (
        <VStack gap="11px" align="stretch">
          <HStack justifyContent="space-between" color="accent.one.default" width="190px">
            <Text as="p" fontWeight="medium" color="inherit">
              Container Temperatures
            </Text>
            <IconButton
              variant="unstyled"
              minWidth="18px"
              height="fit-content"
              padding="0px"
              fontSize="12px"
              onClick={() => setState({ ...state, temperatureIsExpanded: !state.temperatureIsExpanded })}
            >
              {state.temperatureIsExpanded ? <FaChevronDown /> : <FaChevronRight />}
            </IconButton>
          </HStack>
          {state.temperatureIsExpanded && (
            <Grid gap="20px" templateColumns="repeat(4, 1fr)" width="100%">
              <CustomFormControl
                width="100%"
                label={
                  <>
                    Front&nbsp;
                    <Tooltip
                      content="Records the temperature at the front of the container to monitor temperature distribution."
                      positioning={{ placement: 'right-end' }}
                    >
                      <IconButton
                        width="14px"
                        height="14px"
                        padding="0"
                        minW="auto"
                        borderRadius="50%"
                        color="#878787"
                        variant="unstyled"
                      >
                        <IoInformationCircleOutline size="14px" />
                      </IconButton>
                    </Tooltip>
                  </>
                }
              >
                <Input
                  type="number"
                  step="0.01"
                  min="-80"
                  name="container-front-temp"
                  value={get(coldStoreDetails, 'containerFrontTemp', '')}
                  disabled={disabled}
                  className="form-control"
                  onChange={e => onObjectValueChange('coldstoreDetails', 'containerFrontTemp', e.target.value || '')}
                  onWheelCapture={e => {
                    e.target.blur();
                  }}
                />
              </CustomFormControl>
              <CustomFormControl
                width="100%"
                label={
                  <>
                    Middle&nbsp;
                    <Tooltip
                      content="Records the temperature in the middle section of the container for additional temperature monitoring."
                      positioning={{ placement: 'right-end' }}
                    >
                      <IconButton
                        width="14px"
                        height="14px"
                        padding="0"
                        minW="auto"
                        borderRadius="50%"
                        color="#878787"
                        variant="unstyled"
                      >
                        <IoInformationCircleOutline size="14px" />
                      </IconButton>
                    </Tooltip>
                  </>
                }
              >
                <Input
                  type="number"
                  step="0.01"
                  min="-80"
                  name="container-middle-temp"
                  value={get(coldStoreDetails, 'containerMiddleTemp', '')}
                  disabled={disabled}
                  className="form-control"
                  onChange={e => onObjectValueChange('coldstoreDetails', 'containerMiddleTemp', e.target.value || '')}
                  onWheelCapture={e => {
                    e.target.blur();
                  }}
                />
              </CustomFormControl>
              <CustomFormControl
                width="100%"
                label={
                  <>
                    Back&nbsp;
                    <Tooltip
                      content="Records the temperature at the back of the container to complete temperature distribution assessment."
                      positioning={{ placement: 'right-end' }}
                    >
                      <IconButton
                        width="14px"
                        height="14px"
                        padding="0"
                        minW="auto"
                        borderRadius="50%"
                        color="#878787"
                        variant="unstyled"
                      >
                        <IoInformationCircleOutline size="14px" />
                      </IconButton>
                    </Tooltip>
                  </>
                }
              >
                <Input
                  type="number"
                  step="0.01"
                  min="-80"
                  name="container-back-temp"
                  value={get(coldStoreDetails, 'containerBackTemp', '')}
                  disabled={disabled}
                  className="form-control"
                  onChange={e => onObjectValueChange('coldstoreDetails', 'containerBackTemp', e.target.value || '')}
                  onWheelCapture={e => {
                    e.target.blur();
                  }}
                />
              </CustomFormControl>
              {/* <!-- UNITS OF MEASURE --> */}
              <Box width="100%" display="flex" justifyContent="space-between">
                <Box display="flex" width="50%">
                  <SelectField
                    label={
                      <>
                        Units of Measure&nbsp;
                        <Tooltip
                          content="Selects the unit of temperature measurement from °F, °C, or °K for standardized temperature reporting."
                          positioning={{ placement: 'right-end' }}
                        >
                          <IconButton
                            width="14px"
                            height="14px"
                            padding="0"
                            minW="auto"
                            borderRadius="50%"
                            color="#878787"
                            variant="unstyled"
                          >
                            <IoInformationCircleOutline size="14px" />
                          </IconButton>
                        </Tooltip>
                      </>
                    }
                    disabled={disabled}
                    name="temperatureMeasurementUnitId"
                    defaultValue={temperatureMeasurementUnit}
                    placeholder="UOM"
                    onChange={e =>
                      onObjectValueChange('coldstoreDetails', 'temperatureMeasurementUnitId', parseInt(e.value, 10))
                    }
                    options={Constants.TEMPERATURE_MEASUREMENT_UNITS}
                  />
                  </Box>
                  <CustomFormControl width="50%" marginLeft="20px !important">
                    <HStack gap="15px" marginTop="35px">
                      <Checkbox
                        backgroundColor="inherit"
                        type="checkbox"
                        colorScheme="actionSecondary"
                        name="over-red-line"
                        disabled={disabled}
                        marginBottom="0px"
                        checked={coldStoreDetails?.overRedLine || false}
                        onChange={() =>
                          onObjectValueChange('coldstoreDetails', 'overRedLine', !coldStoreDetails.overRedLine)
                        }
                      />
                      <label>
                        Over the red line&nbsp;
                        <Tooltip
                          content='Selects the checkbox if any temperature reading exceeds a predefined threshold ("red line") to identify temperature excursions.'
                          positioning={{ placement: 'right-end' }}
                        >
                          <IconButton
                            width="14px"
                            height="14px"
                            padding="0"
                            minW="auto"
                            borderRadius="50%"
                            color="#878787"
                            variant="unstyled"
                          >
                            <IoInformationCircleOutline size="14px" />
                          </IconButton>
                        </Tooltip>
                      </label>
                    </HStack>
                  </CustomFormControl>
              </Box>
            </Grid>
          )}
        </VStack>
      ) : (
        ''
      )}
      <VStack gap="11px" align="stretch">
        <HStack justifyContent="space-between" color="accent.one.default" width="190px">
          <Text as="p" fontWeight="medium" color="inherit">
            Coldstore Release
          </Text>
          <IconButton
            variant="unstyled"
            minWidth="18px"
            height="fit-content"
            padding="0px"
            fontSize="12px"
            onClick={() => setState({ ...state, coldStoreReleasedIsExpanded: !state.coldStoreReleasedIsExpanded })}
          >
            {state.coldStoreReleasedIsExpanded ? <FaChevronDown /> : <FaChevronRight />}
          </IconButton>
        </HStack>
        {state.coldStoreReleasedIsExpanded && (
          <Grid gap="20px" templateColumns="repeat(4, 1fr)" width="100%">
            <CustomFormControl
              label={
                <>
                  Pickup Date&nbsp;
                  <Tooltip
                    content="Records the date when the goods are scheduled or ready for pickup by the transportation provider."
                    positioning={{ placement: 'right-end' }}
                  >
                    <IconButton
                      width="14px"
                      height="14px"
                      padding="0"
                      minW="auto"
                      borderRadius="50%"
                      color="#878787"
                      variant="unstyled"
                    >
                      <IoInformationCircleOutline size="14px" />
                    </IconButton>
                  </Tooltip>
                </>
              }
            >
              <DateTimeWithTrappedFocus
                id="pickup-date"
                inputProps={{
                  className: 'form-control m-input',
                  disabled: areColdstoreReleaseFieldsDisabled,
                }}
                closeOnSelect
                value={form.shipmentDate}
                onChange={e => {
                  handleDateChange(
                    'shipmentDate',
                    e._isAMomentObject ? e.format('YYYY-MM-DD') : e // eslint-disable-line no-underscore-dangle
                  );
                }}
                className={validationErrors.requiredFields?.form?.includes('shipmentDate') ? 'error' : ''}
                timeFormat={false}
                dateFormat="YYYY-MM-DD"
              />
            </CustomFormControl>
            <CustomFormControl
              label={
                <>
                  Delivery Date&nbsp;
                  <Tooltip
                    content="Records the date when the goods are scheduled or expected to be delivered for logistics planning."
                    positioning={{ placement: 'right-end' }}
                  >
                    <IconButton
                      width="14px"
                      height="14px"
                      padding="0"
                      minW="auto"
                      borderRadius="50%"
                      color="#878787"
                      variant="unstyled"
                    >
                      <IoInformationCircleOutline size="14px" />
                    </IconButton>
                  </Tooltip>
                </>
              }
            >
              <DateTimeWithTrappedFocus
                id="delivery-date"
                inputProps={{
                  className: 'form-control m-input',
                  disabled: areColdstoreReleaseFieldsDisabled,
                }}
                closeOnSelect
                value={form.deliveryDate}
                onChange={e => {
                  handleDateChange(
                    'deliveryDate',
                    e._isAMomentObject ? e.format('YYYY-MM-DD') : e // eslint-disable-line no-underscore-dangle
                  );
                }}
                className={validationErrors.requiredFields?.form?.includes('deliveryDate') ? 'error' : ''}
                timeFormat={false}
                dateFormat="YYYY-MM-DD"
              />
            </CustomFormControl>
            <CustomFormControl
              label={
                <>
                  Delivery Time&nbsp;
                  <Tooltip
                    content="Records the expected delivery time for precise scheduling and coordination of the delivery process."
                    positioning={{ placement: 'right-end' }}
                  >
                    <IconButton
                      width="14px"
                      height="14px"
                      padding="0"
                      minW="auto"
                      borderRadius="50%"
                      color="#878787"
                      variant="unstyled"
                    >
                      <IoInformationCircleOutline size="14px" />
                    </IconButton>
                  </Tooltip>
                    <Checkbox
                    colorScheme="actionSecondary" marginLeft="5px"
                    defaultChecked={state.refuseDeliveryTimePicker}
                    onChange={e => {
                      setState({ ...state, refuseDeliveryTimePicker: e.target.checked });
                    }}
                    disabled={areColdstoreReleaseFieldsDisabled}
                    checked={state.refuseDeliveryTimePicker || false}
                  >
                    N/A
                  </Checkbox>
                </>
              }
            >
              
              <TimePicker
                use12Hours
                disabled={areColdstoreReleaseFieldsDisabled}
                format="h:mm a"
                value={
                  state.refuseDeliveryTimePicker
                    ? null
                    : form.scheduledDeliveryTime && moment(form.scheduledDeliveryTime, 'HH:mm')
                }
                onChange={value => handleDateChange('scheduledDeliveryTime', value && value.format('HH:mm'))}
                className="form-control m-input"
              />
            </CustomFormControl>

            <SelectField
              width="100%"
              label={
                <>
                  Trucking Company&nbsp;
                  <Tooltip
                    content="Specifies the transportation company responsible for transporting the goods"
                    positioning={{ placement: 'right-end' }}
                  >
                    <IconButton
                      width="14px"
                      height="14px"
                      padding="0"
                      minW="auto"
                      borderRadius="50%"
                      color="#878787"
                      variant="unstyled"
                    >
                      <IoInformationCircleOutline size="14px" />
                    </IconButton>
                  </Tooltip>
                </>
              }
              disabled={areColdstoreReleaseFieldsDisabled}
              name="truckingCompany"
              defaultValue={form.transporterId}
              placeholder="Trucking Company"
              onChange={e => onObjectValueChange('form', 'transporterId', e.value)}
              options={transporterOptions}
            />
          </Grid>
        )}
      </VStack>
    </VStack>
  );
};

ColdStoreDetails.propTypes = propTypes;

export default ColdStoreDetails;
